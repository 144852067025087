export function getRatio(imageData): number | null {
  if (imageData.ratio && !Number.isNaN(imageData.ratio)) {
    return imageData.ratio;
  }

  if (imageData.width && imageData.height) {
    const hw = imageData.height / imageData.width;
    const hwFix = parseFloat(hw.toFixed(5));

    return Math.floor(hwFix * 100);
  }

  return null;
}
