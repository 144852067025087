import { css } from '@amedia/brick-tokens';

import type { PillboxData } from './types.js';

export const pillBoxStyle: (variants: PillboxData) => string = css({
  '@property --b-pillbox-color-divider': `{
    syntax: "<color>";
    inherits: true;
    initial-value: 'currentColor';
  }`,
  display: 'flex',
  inlineSize: 'fit-content',
  maxWidth: '100%',
  overflow: 'hidden',
  borderRadius: '$pill',
  flexWrap: 'nowrap',
  '--_b-pillbox-color-divider': 'currentColor',
  flexShrink: '0',
  '& brick-pill:first-of-type': {
    fontWeight: '$pillBoldM',
    '@bp500': {
      fontWeight: '$pillBoldL',
    },
  },
  '& brick-pill:only-child:not([data-version="breaking"])': {
    fontWeight: '$pillM',
    '@bp500': {
      fontWeight: '$pillL',
    },
  },
  '&[data-filled="true"]': {
    '--_b-pillbox-color-divider': 'var(--brick-colors-pillNoneDivider)', //referencing color token by name
  },

  '& brick-pill': {
    paddingInline: '$x2s',
    '& + brick-pill': {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderImage:
        'linear-gradient(transparent calc(100% - $$dividerHeightLocal), var(--b-pillbox-color-divider, var(--_b-pillbox-color-divider)) calc(100% - $$dividerHeightLocal) $$dividerHeightLocal, transparent $$dividerHeightLocal) 1',
      borderTop: 0,
      borderRight: 0,
      borderBottom: 0,
    },
    '&[data-skin="black"] + brick-pill': {
      '--_b-pillbox-color-divider': 'var(--brick-colors-pillBlackDivider)',
    },
    '&[data-skin="sport"] + brick-pill': {
      '--_b-pillbox-color-divider': 'var(--brick-colors-pillSportDivider)',
    },
    '&[data-skin="commercial"] + brick-pill': {
      '--_b-pillbox-color-divider': 'var(--brick-colors-pillCommercialDivider)',
    },
    '&[data-skin="shopping"] + brick-pill': {
      '--_b-pillbox-color-divider': 'var(--brick-colors-pillShoppingDivider)',
    },
    '&[data-skin="betting"] + brick-pill': {
      '--_b-pillbox-color-divider': 'var(--brick-colors-pillBettingDivider)',
    },
  },

  '& brick-pill[data-version="vignette"]:first-of-type': {
    overflow: 'visible',
  },
  '& brick-pill[data-version="vignette"] ~ [data-version="vignette"]': {
    overflow: 'hidden',
  },

  variants: {
    filled: {
      true: {
        '& brick-pill': {
          '& + brick-pill': {
            $$dividerHeightLocal: '75%',
          },
          '&[data-version="alt"] + brick-pill': {
            '--_b-pillbox-color-divider': 'transparent',
          },
          '&[data-version="untold"] + brick-pill': {
            '--_b-pillbox-color-divider': 'transparent',
          },
          '&[data-skin="black"] + brick-pill': {
            '--_b-pillbox-color-divider':
              'var(--brick-colors-pillBlackFilledDivider)',
          },
          '&[data-skin="custom-two"] + brick-pill': {
            '--_b-pillbox-color-divider':
              'var(--custom-background-color-two-front, var(--brick-colors-pillCustomTwoFilledDivider))',
          },
          '&[data-skin="custom-three"] + brick-pill': {
            '--_b-pillbox-color-divider':
              'var(--custom-background-color-three-front, var(--brick-colors-pillCustomThreeFilledDivider))',
          },
          '&[data-skin="highlight"] + brick-pill': {
            '--_b-pillbox-color-divider':
              'var(--brick-colors-pillHighlightFilledDivider)',
          },
          '&[data-skin="opinion"] + brick-pill': {
            '--_b-pillbox-color-divider':
              'var(--opinion-color-front, var(--brick-colors-pillOpinionFilledFg))',
          },
          '&[data-skin="black"][data-version="breaking"] + brick-pill': {
            '--_b-pillbox-color-divider': 'transparent',
          },
          '&[data-skin="custom-one"] + brick-pill': {
            '--_b-pillbox-color-divider':
              'var(--custom-background-color-one-front, var(--brick-colors-pillCustomOneFilledDivider))',
          },
          '&[data-skin="commercial"] + brick-pill': {
            '--_b-pillbox-color-divider':
              'var(--brick-colors-pillCommercialFilledDivider)',
          },
          '&[data-skin="shopping"] + brick-pill': {
            '--_b-pillbox-color-divider':
              'var(--brick-colors-pillShoppingFilledDivider)',
          },
          '&[data-skin="sport"] + brick-pill': {
            '--_b-pillbox-color-divider':
              'var(--brick-colors-pillSportFilledDivider)',
          },
          '&[data-skin="betting"] + brick-pill': {
            '--_b-pillbox-color-divider':
              'var(--brick-colors-pillBettingFilledDivider)',
          },
          '&[data-skin][data-version="alt"] + brick-pill': {
            '--_b-pillbox-color-divider': 'transparent',
          },
          '&[data-skin][data-version="untold"] + brick-pill': {
            '--_b-pillbox-color-divider': 'transparent',
          },
        },
      },
      false: {
        $$dividerHeightLocal: '100%',
        overflow: 'visible', // to allow space for icon animation
        '& :first-child': {
          paddingInlineStart: 'unset',
        },
        '& brick-pill': {
          '&:not([data-version="breaking"]):last-child': {
            overflow: 'hidden',
          },
        },
      },
    },
  },
  defaultVariants: {
    filled: false,
  },
});
