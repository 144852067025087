import { BrickElement, defineCustomElement } from '@amedia/brick-template';
import { Design, themeList } from '@amedia/brick-tokens';

import { breakingTextTemplate } from './template';
import type { BreakingNewsText } from './types';
import { mapToBreakingSkin } from './utils';
import { breakingElement } from './styles.js';

@defineCustomElement({
  selector: 'breaking-text',
})
export class BreakingText extends BrickElement {
  data: BreakingNewsText;

  constructor(data: BreakingNewsText) {
    super();
    this.data = data;
  }

  async connectedCallback() {
    this.classList.add(`${breakingElement}`);
    this.setData();
    super.connectedCallback();

    this.addEventListener(
      'brick-teaser:breakingText',
      this._updateBreakingTitle
    );
  }

  async disconnectedCallback() {
    super.disconnectedCallback();

    this.removeEventListener(
      'brick-teaser:breakingText',
      this._updateBreakingTitle
    );
  }

  setData() {
    this.data = {
      text: this.dataset.text || '',
      color: this.dataset.color || '',
      disableSonar: this.dataset.disableSonar === 'true',
      theme: this.teaserTheme(),
      skin: mapToBreakingSkin(this.dataset.skin),
    };
  }

  get HTML() {
    return breakingTextTemplate(this.data, false);
  }

  teaserTheme(): Design {
    let theme;
    if (this.getAttribute('data-theme')) {
      theme = this.getAttribute('data-theme');
    } else {
      const brickTeaserParent = this.closest('brick-teaser-v16');
      if (brickTeaserParent) {
        theme = brickTeaserParent.getAttribute('data-theme') || 'alfa';
      }
    }

    if (themeList.includes(theme)) {
      return theme as Design;
    }

    return 'alfa';
  }

  private _updateBreakingTitle(event: CustomEventInit) {
    const textElement = this.querySelector('.breaking-news-text');
    const newText = `${event.detail}:`;

    if (!textElement || !newText || newText === this.data.text) {
      return;
    }

    this.dataset.text = newText;
    this.data.text = newText;

    textElement.innerHTML = newText;
  }
}
