import { css } from '@amedia/brick-tokens';

export const carouselContextStyles = css({
  'brick-carousel &': {
    '--b-teaser-borderRadius': 'var(--brick-radii-baseM)',
    '--b-teaser-padding': 0,
    '--b-teaser-padding-content-top': 'var(--brick-space-x2s)',
    '--b-teaser-padding-content-right': 'var(--brick-space-x2s)',
    '--b-teaser-padding-content-bottom': 'var(--brick-space-x2s)',
    '--b-teaser-padding-content-left': 'var(--brick-space-x2s)',
    '--b-teaser-padding-noImageContent': 'var(--brick-space-x2s)',
    '--brick-space-teaserStackAL': 0,
    '--brick-space-teaserStackAM': 0,
    '--b-teaser-padding-videoPlayer': 0,
    '--b-teaser-padding-premium-wrap': 'var(--brick-space-x2s)',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    background: 'var(--brick-colors-baseBg)',
    boxSizing: 'border-box',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'var(--brick-colors-utilitySecondaryBg)',
    borderRadius: 'var(--brick-radii-baseM)',
    backgroundClip: 'padding-box',

    '&:has(:not(.has-image))': {
      '--b-teaser-padding': 0,
    },

    '&:has(:not(.marker))': {
      '--b-teaser-padding-overlay': 'var(--brick-space-x2s)',
    },

    '&[data-theme="alfa"], &[data-theme="charlie"]': {
      '--b-teaser-padding-content-top': 'calc(var(--brick-sizes-avatarS) / 6)',
      '--b-teaser-padding-content-left': 'calc(var(--brick-sizes-avatarS) / 4)',
    },

    '&[data-teaser-type="sport"]': {
      '--b-teaser-padding-content-top': 'var(--brick-space-x3)',
      '--b-teaser-padding-content-bottom': 'var(--brick-space-x3)',
      '--b-teaser-padding-sport-premium': 'var(--brick-space-x2s)',
      '--b-teaser-padding-sport-title': 'var(--brick-space-x2s)',
      '--b-teaser-padding-sport-pills':
        'var(--brick-space-x2s) var(--brick-space-x2s) 0',

      '& .pretitle': {
        fontSize: 'var(--brick-fontSizes-pretitleM)',
      },
    },

    '&[data-teaser-type="opinion"][data-theme="bravo"]': {
      '--b-teaser-padding-content-top': 'calc($space$teaserContentYInsetM * 2)',
      '--b-teaser-padding-content-right': 'calc($space$teaserContentXInsetM)',
      '--b-teaser-padding-content-bottom':
        'calc($space$teaserContentYInsetM * 2)',
      '--b-teaser-padding-content-left': 'calc($space$teaserContentXInsetM)',
    },

    '&[data-theme="charlie"][data-teaser-type="sport"]': {
      '--b-teaser-gridTemplateAreas': '"image image" "title title" ". premium"',
    },

    '&[data-teaser-type="feature"]': {
      '--b-teaser-padding-pill-wrap': '0 0 0 var(--brick-space-x2s)',

      '& .title-container': {
        fontSize:
          'clamp(--brick-fontSizes-titleFeatureXs,1vw + 0.68rem,--brick-fontSizes-titleFeatureM)',
        '@supports (container: inline-size)': {
          fontSize:
            'clamp(--brick-fontSizes-titleFeatureXs,--brick-sizes-titleCqi + --brick-fontSizes-titleCqiBase,--brick-fontSizes-titleFeatureM)',
        },
      },
      '& .pretitle': {
        fontSize: 'var(--brick-fontSizes-pretitleM)',
      },
    },

    '&:has([itemprop="author"])': {
      '--b-teaser-size-avatar': 'var(--brick-sizes-avatarS)',
      '--b-teaser-padding-overlay-byline': 'var(--brick-space-x2s)',

      '& p': {
        fontFamily: 'var(--brick-fonts-avatarMetaM)',
        fontSize: 'var(--brick-fontSizes-avatarMetaM)',
        fontWeight: 'var(--brick-fontWeights-avatarMetaM)',
        lineHeight: 'var(--brick-lineHeights-avatarMetaM)',
        letterSpacing: 'var(--brick-letterSpacings-avatarMetaM)',
      },
    },

    '&:has([data-opinion-design="bubble"])': {
      '--b-teaser-padding-footer': 'var(--brick-space-x2)',
      '--b-teaser-padding-byline-left': 'var(--brick-space-x2s)',
      '--b-teaser-padding-opinion-bubble-wrap': 0,
      '--b-teaser-padding-opinion-bubble': 'var(--brick-space-x2s)',
      '--b-teaser-placement-opinion-bubble-arrow':
        'calc(var(--b-teaser-size-avatar) / 2)',

      '& .title-container .title': {
        '--b-teaser-fontSize-opinion-title':
          'clamp(--brick-fontSizes-titleOpinionXs,1vw + 0.68rem,--brick-fontSizes-titleOpinionS)',

        '@supports (container: inline-size)': {
          '--b-teaser-fontSize-opinion-title':
            'clamp(--brick-fontSizes-titleOpinionXs,--brick-sizes-titleCqi + --brick-fontSizes-titleCqiBase,--brick-fontSizes-titleOpinionS)',
        },
      },
    },

    '& [class*="body"]': {
      flexGrow: 1,
      gap: 0,
    },

    '@bp532': {
      '--b-teaser-padding-overlay': 'var(--brick-space-x2)',
      '--b-teaser-padding-content-top': 'var(--brick-space-x2)',
      '--b-teaser-padding-content-right': 'var(--brick-space-x2)',
      '--b-teaser-padding-content-bottom': 'var(--brick-space-x2)',
      '--b-teaser-padding-content-left': 'var(--brick-space-x2)',
      '--b-teaser-padding-noImageContent': 'var(--brick-space-x2)',
      '--b-teaser-padding-premium-wrap': 'var(--brick-space-x2)',

      '&:has(:not(.marker))': {
        '--b-teaser-padding-overlay': 'var(--brick-space-x2)',
      },

      '&[data-teaser-type="sport"]': {
        '--b-teaser-padding-sport-premium': 'var(--brick-space-x2)',
        '--b-teaser-padding-sport-title': 'var(--brick-space-x2)',
        '--b-teaser-padding-sport-pills':
          'var(--brick-space-x2) var(--brick-space-x2) 0',
      },
      '&[data-teaser-type="feature"]': {
        '--b-teaser-padding-pill-wrap': '0 0 0 var(--brick-space-x2)',
      },
      '&:has([itemprop="author"])': {
        '--b-teaser-padding-overlay-byline': 'var(--brick-space-x2)',
      },
      '&:has([data-opinion-design="bubble"])': {
        '--b-teaser-padding-byline-left': 'var(--brick-space-x2)',
        '--b-teaser-padding-opinion-bubble': 'var(--brick-space-x2)',
      },
      '&[data-teaser-type="opinion"][data-theme="bravo"]': {
        '--b-teaser-padding-content-top':
          'calc($space$teaserContentYInsetL * 2)',
        '--b-teaser-padding-content-right': 'calc($space$teaserContentXInsetL)',
        '--b-teaser-padding-content-bottom':
          'calc($space$teaserContentYInsetL * 2)',
        '--b-teaser-padding-content-left': 'calc($space$teaserContentXInsetL)',
      },
    },
  },
});
