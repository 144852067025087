import { css } from '@amedia/brick-tokens';

export const cssPropertyDeclarations = css({
  '@property --b-teaser-color-bg': `{
        syntax: "<color>";
        inherits: true;
        initial-value: 'inherit';
      }`,
  '@property --b-teaser-color-fg': `{
        syntax: "<color>";
        inherits: true;
        initial-value: 'inherit';
      }`,
  '@property --b-teaser-color-divider': `{
        syntax: "<color>";
        inherits: true;
        initial-value: 'currentColor';
      }`,
  '@property --b-teaser-color-breakingBoost': `{
        syntax: "<color>";
        inherits: true;
        initial-value: 'inherit';
      }`,
  '@property --b-teaser-color-pulseStart': `{
        syntax: "<color>";
        inherits: true;
        initial-value: 'inherit';
      }`,
  '@property --b-teaser-color-pulseEnd': `{
        syntax: "<color>";
        inherits: true;
        initial-value: 'inherit';
      }`,
  '@property --b-teaser-gridTemplateAreas': `{
        syntax: "<string>";
        inherits: true;
        initial-value: '"image" "title"'
  }`,
  '@property --b-teaser-padding-videoPlayer': `{
        syntax: "<length> | <percentage>";
        inherits: true;
        initial-value: var(--brick-space-teaserPlayerInsetM);
      }`,
  '@property --b-teaser-padding': `{
        syntax: "<length> | <percentage>";
        inherits: false;
        initial-value: 0px;
      }`,
  '@property --b-teaser-borderRadius': `{
        syntax: "<length> | <percentage>";
        inherits: false;
        initial-value: var(--brick-radii-teaser);
      }`,
  '@property --b-teaser-boxShadow': `{
        syntax: "<length> | <percentage>";
        inherits: false;
        initial-value: var(--brick-shadows-boxShadowTeaser);
      }`,
  '@property --b-teaser-padding-content-top': `{
        syntax: "<length> | <percentage>";
        inherits: true;
        initial-value: var(--brick-space-teaserContentYInsetM);
      }`,
  '@property --b-teaser-padding-content-right': `{
        syntax: "<length> | <percentage>";
        inherits: true;
        initial-value: var(--brick-space-teaserContentXInsetM);
      }`,
  '@property --b-teaser-padding-content-bottom': `{
        syntax: "<length> | <percentage>";
        inherits: true;
        initial-value: var(--brick-space-teaserContentYInsetM);
      }`,
  '@property --b-teaser-padding-content-left': `{
        syntax: "<length> | <percentage>";
        inherits: true;
        initial-value: var(--brick-space-teaserContentXInsetM);
      }`,
  '@property --b-teaser-padding-overlay-byline': `{
        syntax: "<length> | <percentage>";
        inherits: true;
        initial-value: var(--brick-space-teaserBoxInsetM);
      }`,
  '@property --b-teaser-size-avatar': `{
        syntax: "<height>";
        inherits: true;
        initial-value: var(--_b-teaser-size-avatar);
      }`,
  '@property --b-teaser-padding-byline-top': `{
        syntax: "<length> | <percentage>";
        inherits: true;
        initial-value: 0px;
      }`,
  '@property --b-teaser-padding-byline-right': `{
        syntax: "<length> | <percentage>";
        inherits: true;
        initial-value: 0px;
      }`,
  '@property --b-teaser-padding-byline-bottom': `{
        syntax: "<length> | <percentage>";
        inherits: true;
        initial-value: 0px;
      }`,
  '@property --b-teaser-padding-byline-left': `{
        syntax: "<length> | <percentage>";
        inherits: true;
        initial-value: 0px;
      }`,
  '@property --b-premium-color-bg': `{
        syntax: "<color>";
        inherits: true;
        initial-value: var(--brick-colors-premiumIconBg);
      }`,
  '@property --b-premium-color-fg': `{
        syntax: "<color>";
        inherits: true;
        initial-value: var(--brick-colors-premiumIconFg);
      }`,
  '@property --b-teaser-padding-footer': `{
        syntax: "<length> | <percentage>";
        inherits: true;
        initial-value: 0px;
      }`,
  '@property --b-teaser-margin-divider': `{
        syntax: "<length> | <percentage>";
        inherits: true;
        initial-value: 0px;
      }`,
  '@property --b-teaser-borderRadius-image': `{
        syntax: "<border-radius>";
        inherits: false;
        initial-value: var(--brick-radii-teaserImage);
      }`,
  '@property --b-teaser-padding-overlay': `{
        syntax: "<length> | <percentage>";
        inherits: true;
        initial-value: var(--brick-space-teaserBoxInsetM);
      }`,
  '@property --b-teaser-fontSize-opinion-title': `{
        syntax: "<length>";
        inherits: true;
        initial-value: clamp(var(--brick-fontSizes-titleOpinionXs), 1.5vw + 0.5rem,var(--brick-fontSizes-titleOpinionL));
      }`,
  '@property --b-teaser-padding-noImageContent': `{
        syntax: "<length> | <percentage>";
        inherits: true;
        initial-value: var(--brick-space-teaserContentYInsetM) var(--brick-space-teaserContentXInsetM);
      }`,
  '@property --b-teaser-padding-pill-wrap': `{
        syntax: "<length> | <percentage>";
        inherits: true;
        initial-value: '0 0 2px 0';
      }`,
  '@property --b-teaser-padding-premium-wrap': `{
        syntax: "<length> | <percentage>";
        inherits: true;
        initial-value: var(--brick-space-teaserStackBM) var(--brick-space-teaserXInsetM) var(--brick-space-teaserYInsetM) 0;
      }`,
  '@property --b-teaser-padding-opinion-bubble-wrap': `{
        syntax: "<length> | <percentage>";
        inherits: true;
        initial-value: var(--brick-colors-supportiveNoneBg);
      }`,
  '@property --b-teaser-color-opinion-bubble-bg': `{
        syntax: "<color>";
        inherits: true;
        initial-value: var(--brick-colors-teaserBubbleBg);
      }`,
  '@property --b-teaser-padding-opinion-bubble': `{
        syntax: "<length> | <percentage>";
        inherits: true;
        initial-value: var(--brick-space-teaserBubbleInsetM);
      }`,
  '@property --b-teaser-placement-opinion-bubble-arrow': `{
            syntax: "<length> | <percentage>";
            inherits: true;
            initial-value:  calc(var(--brick-sizes-avatarM) / 3);
          }`,
  '@property --b-teaser-padding-sport-premium': `{
        syntax: "<length> | <percentage>";
        inherits: true;
        initial-value: var(--brick-space-cardYM) var(--brick-space-cardXM) var(--brick-space-cardYM) 0;
      }`,
  '@property --b-teaser-padding-sport-pills': `{
        syntax: "<length> | <percentage>";
        inherits: true;
        initial-value: var(--brick-space-cardYM) var(--brick-space-cardXM) 0;
      }`,
  '@property --b-teaser-padding-sport-title': `{
        syntax: "<length> | <percentage>";
        inherits: true;
        initial-value: var(--brick-space-teaserStackAM) var(--brick-space-cardXM) var(--brick-space-cardYM) var(--brick-space-cardXM);
      }`,
  '@property --b-teaser-padding-footer-content': `{
        syntax: "<length> | <percentage>";
        inherits: true;
        initial-value: var(--brick-space-teaserFooterTipusTopM) var(--brick-space-teaserFooterTipusRightM) var(--brick-space-teaserFooterTipusBottomM) var(--brick-space-teaserFooterTipusLeftM);
      }`,
});
