import { BrickElement, defineCustomElement } from '@amedia/brick-template';

/* 
  Use intersectionObserver to show and hide video preview. 
  5 seconds is max without breaking WCAG rules for browser initiated animation  
*/

// Custom wrapper for video preview
@defineCustomElement({
  selector: 'video-preview',
})
export class VideoPreview extends BrickElement {
  intersectionObserver: IntersectionObserver | undefined;
  threshold: number;
  rootMargin: string | undefined;
  timer: number;
  mediaQuery: string;
  smallDevice: MediaQueryList;

  constructor() {
    super();
    this.threshold = 0.5;
    this.rootMargin = '0px';
    this.timer = 5000;
    this.mediaQuery = '(max-width: 532px)';
    this.smallDevice = window.matchMedia(this.mediaQuery);
  }

  async connectedCallback() {
    super.connectedCallback();
    const isSmallDevice = this.smallDevice.matches;
    // Add intersectionObserver to show and hide video preview if smallDevice only
    if (isSmallDevice) {
      this.intersectionObserver = new IntersectionObserver(
        this.handleIntersectionCallback.bind(this),
        {
          root: null,
          rootMargin: this.rootMargin,
          threshold: this.threshold,
        }
      );
      this.intersectionObserver.observe(this);
    }
  }

  async disconnectedCallback() {
    if (this.intersectionObserver) {
      this.intersectionObserver.disconnect();
    }
    super.disconnectedCallback();
  }

  handleIntersectionCallback(entries) {
    entries.forEach((entry: IntersectionObserverEntry) => {
      const imageContainer = this.querySelector(
        '[data-preview-image]'
      ) as HTMLElement;

      if (entry.isIntersecting) {
        imageContainer.classList.add('hide');
        setTimeout(() => {
          imageContainer.classList.remove('hide');
        }, this.timer);
      }
    });
  }

  get HTML() {
    return '';
  }
}
