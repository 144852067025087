import { css } from '../../styles';

export const breakingStyle = css({
  display: 'inline-block',
  position: 'relative',
  marginInlineEnd: '0',
  '& div': {
    aspectRatio: '1/1',
    position: 'absolute',
    width: 'auto',
    height: '70%',
    top: '17%',
  },
  '& svg': {
    width: 'auto',
    height: '100%',
  },

  '&.breaking-news-text': {
    textTransform: 'uppercase',
  },
});

export const breakingPulseStyle = css({
  '--_b-teaser-color-pulseStart':
    'var(--b-teaser-color-pulseStart, var(--brick-colors-pillNonePulseStartBg))',
  '--_b-teaser-color-pulseEnd':
    'var(--b-teaser-color-pulseEnd, var(--brick-colors-pillNonePulseEndBg))',
  '--brick--pulseColor70': 'var(--_b-teaser-color-pulseStart)',
  '--brick--pulseColor0': 'var(--_b-teaser-color-pulseEnd)',

  display: 'inline-block',
  position: 'relative',
  marginInlineEnd: '0.7em',
  '& brick-icon-v2': {
    display: 'inline-flex',
    marginInlineStart: 'calc($x1 * 0.2)',
    marginInlineEnd: 'calc($x1 * 0.3)',
  },
  '& div': {
    aspectRatio: '1/1',
    position: 'absolute',
    width: 'auto',
    height: '70%',
    top: '15%',
  },
  '& svg': {
    width: 'auto',
    height: '100%',
  },

  '@bp500': {
    '& brick-icon-v2': {
      marginInlineStart: 'calc($x1 * 0.2)',
      marginInlineEnd: 'calc($x1 * 0.7)',
    },
  },
  variants: {
    skin: {
      none: {
        '--_b-icon-color': 'var(--brick-colors-pillNonePulseBg)',
        '--_b-teaser-color-pulseStart':
          'var(--b-teaser-color-pulseStart, var(--brick-colors-pillNonePulseStartBg))',
        '--_b-teaser-color-pulseEnd':
          'var(--b-teaser-color-pulseEnd, var(--brick-colors-pillNonePulseEndBg))',
      },
      black: {
        '--_b-icon-color': 'var(--brick-colors-pillBlackPulseBg)',
        '--_b-teaser-color-pulseStart':
          'var(--b-teaser-color-pulseStart, var(--brick-colors-pillBlackPulseStartBg))',
        '--_b-teaser-color-pulseEnd':
          'var(--b-teaser-color-pulseEnd, var(--brick-colors-pillBlackPulseEndBg))',
      },
      'custom-one': {
        '--_b-icon-color': 'var(--brick-colors-pillCustomOnePulseBg)',
        '--_b-teaser-color-pulseStart':
          'var(--b-teaser-color-pulseStart, var(--brick-colors-pillCustomOnePulseStartBg))',
        '--_b-teaser-color-pulseEnd':
          'var(--b-teaser-color-pulseEnd, var(--brick-colors-pillCustomOnePulseEndBg))',
      },
      'custom-two': {
        '--_b-icon-color': 'var(--brick-colors-pillCustomTwoPulseBg)',
        '--_b-teaser-color-pulseStart':
          'var(--b-teaser-color-pulseStart, var(--brick-colors-pillCustomTwoPulseStartBg))',
        '--_b-teaser-color-pulseEnd':
          'var(--b-teaser-color-pulseEnd, var(--brick-colors-pillCustomTwoPulseEndBg))',
      },
      'custom-three': {
        '--_b-icon-color': 'var(--brick-colors-pillCustomThreePulseBg)',
        '--_b-teaser-color-pulseStart':
          'var(--b-teaser-color-pulseStart,  var(--brick-colors-pillCustomThreePulseStartBg))',
        '--_b-teaser-color-pulseEnd':
          'var(--b-teaser-color-pulseEnd, var(--brick-colors-pillCustomThreePulseEndBg))',
      },
      highlight: {
        '--_b-icon-color': 'var(--brick-colors-pillHighlightPulseBg)',
        '--_b-teaser-color-pulseStart':
          'var(--b-teaser-color-pulseStart, var(--brick-colors-pillHighlightPulseStartBg))',
        '--_b-teaser-color-pulseEnd':
          'var(--b-teaser-color-pulseEnd, var(--brick-colors-pillHighlightPulseEndBg))',
      },
      sport: {
        '--_b-icon-color': 'var(--brick-colors-pillSportPulseBg)',
        '--_b-teaser-color-pulseStart':
          'var(--b-teaser-color-pulseStart, var(--brick-colors-pillSportPulseStartBg))',
        '--_b-teaser-color-pulseEnd':
          'var(--b-teaser-color-pulseEnd, var(--brick-colors-pillSportPulseEndBg))',
      },
    },
  },
  defaultVariants: {
    skin: 'none',
  },
});

export const breakingTextStyle = css({
  display: 'inline-block',
  position: 'relative',
  marginInlineEnd: '0',
  textTransform: 'uppercase',
  fontFamily: '$baseBoostM',
  letterSpacing: '$baseBoostM',
  fontWeight: '$baseBoostM',
  '--_b-color-breakingBoost': 'inherit',
  color: 'var(--b-teaser-color-breakingBoost, var(--_b-color-breakingBoost))',
  variants: {
    skin: {
      none: {
        '--_b-color-breakingBoost': '$colors$supportiveNoneBreakingFg',
      },
      black: {
        '--_b-color-breakingBoost': '$colors$supportiveBlackBreakingFg',
      },
      'custom-one': {
        '--_b-color-breakingBoost':
          'var(--custom-background-color-one-front, $colors$supportiveCustomOneFg)',
      },
      'custom-two': {
        '--_b-color-breakingBoost':
          'var(--custom-background-color-two-front, $colors$supportiveCustomTwoFg)',
      },
      sport: {},
      highlight: {
        '--_b-color-breakingBoost': '$colors$supportiveHighlightBreakingFg',
      },
    },
  },
  defaultVariants: {
    skin: 'none',
  },
});
export const breakingElement = css({
  display: 'inline',
  gap: '$x2s',
  alignItems: 'baseline',
  textDecoration: 'none',
});
