import type { TeaserApi } from '../types';

export const encodeTeaserData = (data: TeaserApi): string => {
  if (!data) {
    return '';
  }
  const escapedStr = encodeURIComponent(JSON.stringify(data)).replace(
    /%([0-9A-F]{2})/g,
    (match, p1) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      String.fromCharCode(`0x${p1}`)
  );
  return encodeBase64(escapedStr);
};
export const decodeTeaserData = (data: string): TeaserApi => {
  const decode64Str = decodeBase64(data);
  const escapedStr = decodeURIComponent(
    decode64Str
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );
  return JSON.parse(escapedStr);
};

const encodeBase64 = (data: string): string => {
  return btoa(data);
};
const decodeBase64 = (data: string): string => {
  return atob(data);
};
