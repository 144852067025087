import { css } from '@amedia/brick-tokens';

export const plusStyles = {
  height: '$premiumIconM',
  width: '$premiumIconM',

  '@bp532': {
    height: '$premiumIconL',
    width: '$premiumIconL',
  },
};

export const plusAllStyles = {
  height: '34px',
  width: '34px',

  '@bp532': {
    height: '36px',
    width: '36px',
  },
};

export const premiumStyle = css({
  '--_b-premium-color-bg':
    'var(--b-premium-color-bg, var(--brick-colors-premiumIconBg))',
  '--_b-premium-color-fg':
    'var(--b-premium-color-fg, var(--brick-colors-premiumIconFg))',
  '--_b-premium-padding': '0px',
  display: 'inline-flex',
  boxSizing: 'border-box',

  borderRadius: '$premiumIcon',

  '& svg': {
    height: 'inherit',
    width: '100%',
  },

  variants: {
    theme: {
      alfa: {},
      bravo: {},
      charlie: {},
      nettavisen: {},
    },
    version: {
      plus: {},
      plusall: {
        ...plusAllStyles,
      },
    },
    filled: {
      true: {
        backgroundColor: 'var(--_b-premium-color-bg)',
        color: 'var(--_b-premium-color-fg)',
      },
      false: {},
    },
  },
  compoundVariants: [
    {
      version: 'plus',
      filled: true,
      css: {
        padding: 'var(--_b-premium-padding)',
      },
    },
    {
      version: 'plus',
      theme: 'alfa',
      css: {
        ...plusStyles,
      },
    },
    {
      version: 'plus',
      theme: 'bravo',
      css: {
        ...plusStyles,
      },
    },
    {
      version: 'plus',
      theme: 'charlie',
      css: {
        height: '11px',
        width: '11px',
      },
    },
    {
      version: 'plus',
      theme: 'nettavisen',
      css: {
        ...plusStyles,
      },
    },
    {
      filled: true,
      theme: 'alfa',
      css: {
        '--_b-premium-padding': '2px',
        '@bp532': {
          '--_b-premium-padding': '3px',
        },
      },
    },
    {
      filled: true,
      theme: 'bravo',
      css: {
        '--_b-premium-padding': '2px',
        '@bp532': {
          '--_b-premium-padding': '3px',
        },
      },
    },
    {
      filled: true,
      theme: 'charlie',
      css: {
        '--_b-premium-padding': '2px',
        '@bp532': {
          '--_b-premium-padding': '3px',
        },
      },
    },
    {
      filled: true,
      theme: 'nettavisen',
      css: {
        '--_b-premium-padding': '2px',
      },
    },
  ],
});
