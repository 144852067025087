import type { Design } from '@amedia/brick-tokens';
import type { PillSkin } from '@amedia/brick-pill';

import type { CommercialCategory, Skin, TeaserType } from '../types';

export const getSkin = (color: string): Skin => {
  const skins = {
    'background-black': 'black',
    'custom-one': 'custom-one',
    'background-custom custom-one': 'custom-one',
    'custom-two': 'custom-two',
    'background-custom custom-two': 'custom-two',
    'custom-three': 'custom-three',
    'background-custom custom-three': 'custom-three',
    'background-sport': 'sport',
    'background-yellow': 'highlight',
    'background-highlight': 'highlight',
    'background-opinion': 'opinion',
  };
  return skins[color] || 'none';
};

export const mapToSkin = ({
  theme,
  skin,
  commercialCategory,
  isBreaking,
  filled,
  teaserType,
}: {
  theme: Design;
  skin: Skin;
  isBreaking: boolean;
  filled: boolean;
  commercialCategory?: CommercialCategory;
  teaserType: TeaserType;
}): Skin => {
  if (!!commercialCategory) {
    return 'none';
  }

  if (teaserType === 'feature') {
    switch (skin) {
      case 'black':
        return 'black';
      case 'custom-one':
        return 'custom-one';
      case 'custom-two':
        return 'custom-two';
      case 'custom-three':
        return 'custom-three';
      default:
        return 'none';
    }
  }

  const useSkinOverride =
    (theme === 'nettavisen' && isBreaking && skin === 'black') ||
    (theme === 'nettavisen' && isBreaking && skin === 'none' && filled);

  if (useSkinOverride) {
    return 'highlight';
  }

  return skin;
};

export const mapToPillSkin = ({
  skin,
  commercialCategory,
}: {
  skin: Skin;
  commercialCategory?: CommercialCategory;
}): PillSkin => {
  switch (commercialCategory) {
    case 'commercial':
      return 'commercial';
    case 'shopping':
      return 'shopping';
    case 'betting':
      return 'betting';
    case 'betting_norsk_tipping':
      return 'betting';
  }

  return skin;
};
