import type { TeaserMetaData } from '../types';
import esc from '../utils/escape-html';

export interface Adp {
  id: string;
  testId?: string;
  variantId?: string;
  siteId?: string;
}

const ADP_ARTICLE_TYPE = 'http://schema.org/NewsArticle';
const ADP_MARKETING_TYPE = 'http://schema.org/AdvertiserContentArticle';
const ADP_PAYWALL = 'paywall';
const ADP_FREE = 'free';

/**
 * 06.02.23
 * Bård will find out if what we should do with the following attributes:
 * data-adp-siteid
 * data-adp-teaserid
 * data-adp-variantid
 * data-adp-testid
 *
 * They should be removed, but we need to make sure that ADP will register everything through meta tags
 * */

function adpAbTest(data: Adp) {
  const { id, testId, variantId, siteId } = data;

  if (!testId) {
    return {};
  }

  if (!variantId) {
    return {};
  }

  if (!siteId) {
    return {};
  }

  const teaserId = id.replace(/-[a-z]$/, '');

  const abTestAttributes = `data-adp-siteid="${esc(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    siteId!
  )}" data-adp-teaserid="${esc(teaserId)}" data-adp-variantid="${esc(
    variantId
  )}" data-adp-testid="${esc(testId)}"`;
  const abTestMetaTags = `
        <meta itemprop="https://www.adplogger.no/json-schema/meta-teaser#testid" content="${esc(
          testId
        )}" />
        <meta itemprop="https://www.adplogger.no/json-schema/meta-teaser#variant" content="${esc(
          variantId
        )}" />
        `;

  return { abTestAttributes, abTestMetaTags };
}

function renderMetaTags(tags: Array<TeaserMetaData>): string {
  return tags
    .map(
      (metaType) =>
        `<meta itemprop="${metaType.itemprop}" content="${metaType.content}"/>`
    )
    .join('');
}

export function getLogData(data, removeLogScope) {
  const {
    id,
    adp,
    body: { premiumVersion },
    meta = [],
    isContentMarketing,
  } = data;

  if (removeLogScope) {
    return {};
  }

  const isPremium = Boolean(premiumVersion);
  const adpContentModel = isPremium ? ADP_PAYWALL : ADP_FREE;
  const adpItemType = isContentMarketing
    ? ADP_MARKETING_TYPE
    : ADP_ARTICLE_TYPE;

  const { abTestAttributes = '', abTestMetaTags = '' } = adpAbTest(adp);

  const logAttributes = `itemscope itemtype="${adpItemType}" ${abTestAttributes}`;

  const logDataMarkup = `
  <meta itemprop="adpType" content="teaser" />
  <meta itemprop="contentModel" content="${adpContentModel}"/> 
  <meta itemprop="identifier" content="${id.replace(/-[a-z]$/, '')}"/>
  ${renderMetaTags(meta)}
  ${abTestMetaTags}
  `;

  return { logDataMarkup, logAttributes };
}
