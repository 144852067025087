import type { ButtonVersion, ButtonSize, IconPlacemet } from './types';

export const camelCase = (str: string): string => {
  return str.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
};

export const kebabCase = (str: string): string => {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
};

export const mapToVersion = (version?: string): ButtonVersion => {
  if (version && ['primary', 'secondary', 'outlined'].includes(version)) {
    return version as ButtonVersion;
  }

  return 'primary';
};

export const mapToIconPlacement = (placement?: string): IconPlacemet => {
  if (placement && ['top', 'right', 'left'].includes(placement)) {
    return placement as IconPlacemet;
  }

  return 'left';
};

export const mapToSize = (size?: string): ButtonSize => {
  if (size && ['small', 'medium'].includes(size)) {
    return size as ButtonSize;
  }

  return 'medium';
};
