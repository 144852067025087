// const xAsPercentage = xCoord / rect.width * 100;
// 	const yAsPercentage = yCoord / rect.height * 100;
import type { AOI, ImageAOI, ImageAPI, ImageData } from '../types';

const aoiPercentage = function (img, aoi) {
  return (aoi / img) * 100;
};

const getImageAoi = function ({
  height,
  width,
  aoi,
}: {
  width?: number;
  height?: number;
  aoi?: ImageAOI | AOI;
}): ImageAOI | undefined {
  if (!aoi) {
    return;
  }
  const aoiHeight = aoi.height;
  const aoiWidth = aoi.width;

  return {
    ...aoi,
    heightPercentage: aoiPercentage(height, aoiHeight),
    widthPercentage: aoiPercentage(width, aoiWidth),
    orgHeight: height || 0,
    orgWidth: width || 0,
  };
};

export const aoiCssVar = ({
  aoiX,
  aoiY,
  aoiWidth,
  aoiHeight,
  imageWidth,
  imageHeight,
}) => {
  const x = aoiX;
  const y = aoiY;
  const width_aoi = aoiWidth;
  const height_aoi = aoiHeight;
  const x_aoi = (x / imageWidth) * 100;
  const y_aoi = (y / imageHeight) * 100;
  return { x_aoi, y_aoi, width_aoi, height_aoi };
};

export const getImageData = (image?: ImageAPI): ImageData | undefined => {
  if (!image) return;

  // Check if image.preview exists and ends with .mp4 (only mp4 is valid for preview files)
  if (image.preview && !image.preview.endsWith('.mp4')) {
    delete image.preview;
  }

  if (image && image.imgAoi?.heightPercentage) {
    return image;
  }

  const extendedAoi = getImageAoi({
    height: image.height,
    width: image.width,
    aoi: image.aoi || image.imgAoi,
  });

  return {
    ...image,
    placeholder: !!image.placeholder,
    ...(extendedAoi && { imgAoi: extendedAoi }),
  };
};
