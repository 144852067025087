import { getCssText, sroStyle } from '@amedia/brick-tokens';

import { avatarClass } from './styles';
import { BrickAvatarTemplate } from './types';
import { brickImageSrcset } from '@amedia/brick-image/template';

export { getCssText };
export type { BrickAvatarTemplate };

const renderSro = (dataAlttext) => {
  if (!!dataAlttext) {
    return `<span class="${sroStyle()}">${dataAlttext}</span>`;
  }
  return '';
};

const renderImageOrAbbreviation = (
  dataImg?: string,
  dataAlttext?: string,
  dataLetters?: string,
  dataLettersShape?: string
) => {
  if (!!dataImg) {
    const srcset = brickImageSrcset({
      srcsizes: '50_50, 100_100, 200_200',
      url: dataImg,
    });
    return `<brick-image-v3 data-width="100" data-height="100" data-srcsize="100_100" data-alt-text="${dataAlttext}" data-src="${dataImg}" data-srcset="${srcset}" data-sizes="40px"></brick-image-v3>`;
  }
  if (dataLettersShape === 'shield' || dataLettersShape === 'tshirt') {
    return `<div aria-hidden="true" data-letters-shape="${dataLettersShape}"><span>${dataLetters}</span></div>`;
  }
  return `<span aria-hidden="true">${dataLetters}</span>`;
};
export const brickAvatarTemplate = (data: BrickAvatarTemplate): string => {
  const {
    dataSize,
    dataLetters,
    dataImg = '',
    dataShape = '',
    dataAlttext = '',
    dataLettersShape = '',
  } = data;

  const markup = `<span data-size="${dataSize}" class="${avatarClass()}">${renderSro(
    dataAlttext
  )}${renderImageOrAbbreviation(
    dataImg,
    dataAlttext,
    dataLetters,
    dataLettersShape
  )}</span>`;
  // If isRendered ==='true' it means the markup is rendered serverside, so we do not want to update the DOM client side.
  if (data.isRendered) {
    return `<brick-avatar-v0 data-letters-shape="${dataLettersShape}" data-alttext="${dataAlttext}" data-shape="${dataShape}" data-img="${dataImg}" data-letters="${dataLetters}" data-size="${dataSize}" is-rendered >${markup}</brick-avatar-v0>`;
  }
  // If not, we return the markup that will be attached to the DOM client side, by our web component class
  return `${markup}`;
};

export const renderBrickAvatar = (dataset: BrickAvatarTemplate) => {
  const isRendered = true;
  return `${brickAvatarTemplate({
    ...dataset,
    isRendered,
  })}`;
};
