//data-chromatic="ignore" is used to signal to chromatic not to snapshot this element
export function getVideoPreview({
  url,
  previewUrl,
  imageElement,
}: {
  url?: string;
  previewUrl?: string;
  title: string;
  imageElement: string;
}) {
  let preview = '';

  if (previewUrl) {
    preview = `<div class="preview-img">
                  <video src="${previewUrl}" playsinline autoplay loop muted loading="lazy" class="preview" data-chromatic="ignore"></video>
                </div>`;
  } else if (url) {
    preview = `<div class="preview-video">
                  <video class="preview" autoplay muted loop playsinline>
                    <source src="${url}" type="video/mp4">
                  </video>
                </div>`;
  }

  return `
    <video-preview>
      ${preview}
      <div class="preview-image" ${
        preview ? '' : 'style="opacity: 1;"'
      } data-preview-image data-testid="preview">
       ${imageElement}
      </div>
    </video-preview>`;
}
