import { ghostPill } from '../../pills/pills';
import { overlayStyle } from '../overlay-style.js';
import getPremiumIcon from '../../common/premiumIcon';
import { Overlay } from '../../../types';

import pillBoxTemplate from './pillBoxTemplate';

export default function getTemplateStructure(data: Overlay) {
  const {
    premiumVersion,
    teaserType,
    theme,
    ghost,
    version = '',
    mirror,
    marker,
    pills,
  } = data;
  const overlayClasses = overlayStyle({
    version,
    marker,
  });
  const mirrorClass = mirror ? 'mirror' : '';

  if (ghost && (teaserType === 'alt' || teaserType === 'untold')) {
    return `<div class="overlay_container ${overlayClasses}" data-theme="${theme}">${ghostPill(
      {
        version: teaserType,
        theme,
        text: ghost?.publicationName,
      }
    )}
</div>`;
  }

  const filled = true;

  const premiumTemplate: string = premiumVersion
    ? `<div class="premium-overlay">${getPremiumIcon({
        theme,
        premiumVersion,
        filled,
      })}</div>`
    : '';

  const structureTemplate = {
    alfa: [
      `<div class="overlay_container ${overlayClasses} ${mirrorClass}" data-theme="${theme}" >
        ${pillBoxTemplate(pills)}
        ${premiumTemplate}
       </div>`,
    ],
    charlie: [
      `<div class="overlay_container ${overlayClasses} ${mirrorClass}" data-theme="${theme}">
        ${pillBoxTemplate(pills)}
       </div>`,
    ],
    default: [
      `<div class="overlay_container ${overlayClasses}" data-theme="${theme}">
        ${pillBoxTemplate(pills)}
      </div>`,
    ],

    nettavisen: [
      `<div class="overlay_container ${overlayClasses} ${mirrorClass}" data-theme="${theme}">
        ${pillBoxTemplate(pills)}
        ${premiumTemplate}
       </div>`,
    ],
  };

  return (structureTemplate[theme] || structureTemplate.default).join('');
}
