export default function escapeHTML(s?: string): string | undefined {
  const lookup = {
    '&': '&amp;',
    '"': '&quot;',
    '<': '&lt;',
    '>': '&gt;',
  };
  const escapedString = s && s.replace(/[&"<>]/g, (c) => lookup[c]);

  return escapedString || '';
}
