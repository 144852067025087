import { Design } from '@amedia/brick-tokens';

import { TeaserType } from '../types';

export const useMarker = ({
  theme,
  teaserType,
  color,
  hasImage,
}: {
  theme: Design;
  color: string;
  teaserType: TeaserType;
  hasImage: boolean;
}): boolean => {
  if (theme !== 'nettavisen') {
    return false;
  }

  if (teaserType === 'opinion' && !hasImage) {
    return false;
  }

  if (teaserType === 'feature') {
    return false;
  }

  return (
    color === 'custom-one' ||
    color === 'custom-two' ||
    color === 'background-custom custom-one' ||
    color === 'background-custom custom-two'
  );
};
