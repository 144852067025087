import { renderBrickPillbox } from '@amedia/brick-pill/template';

import { BodyPills, PremiumVersion } from '../../types';
import { mapToPillVersion } from '../../utils/mapToPillVersion';

import { videoPill, galleryPill, vignettePill } from './pills';

export default function noImageBottomPillStack(
  data: BodyPills,
  premiumVersion?: PremiumVersion
) {
  const { teaserType, theme, videoMeta, marker = false, skin, text } = data;
  const isPremium = Boolean(premiumVersion);

  const renderPills =
    teaserType === 'video' || teaserType === 'gallery' || isPremium;
  const filled = marker;
  const pillVersion = mapToPillVersion(teaserType);

  const videoPillText = videoMeta?.videoDuration ? videoMeta.videoDuration : '';

  const pills = `
  ${videoPill({
    version: pillVersion,
    skin,
    theme,
    filled,
    text,
  })}
  ${vignettePill({
    version: 'vignette',
    text: videoPillText,
    skin,
    theme,
    filled,
  })}
  ${galleryPill({
    version: pillVersion,
    text,
    skin,
    theme,
    filled,
  })}
  `;

  return renderPills ? `${renderBrickPillbox({ filled }, pills)}` : '';
}
