import { css } from '@amedia/brick-tokens';

export { css, getCssText, getThemeByDesign } from '@amedia/brick-tokens';

export const mediaBreakpoints = {
  bp1: '(min-width: 533px) and (max-width: 1000px)',
  bp2: '(max-width: 532px)',
};

export const brickTeaserElement = css({
  '.themeNettavisen &': {
    display: 'block',
  },
  '.themeBravo &, .themeAlfa &, .themeCharlie &': {
    height: '100%',
  },
});
