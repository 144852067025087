import { TeaserType } from '../types';

const teaserTypes: TeaserType[] = [
  'commercial',
  'opinion',
  'feature',
  'gallery',
  'alt',
  'untold',
  'sport',
  'review',
  'story',
  'video',
  'teaser',
];

export const mapToTeaserType = (
  teaserType: string,
  isContentMarketing: boolean
): TeaserType => {
  if (isContentMarketing) {
    return 'commercial';
  }

  const isValid = teaserTypes.some((type) => type === teaserType);

  return isValid ? (teaserType as TeaserType) : 'story';
};
