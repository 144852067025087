import { renderBrickPillbox } from '@amedia/brick-pill/template';

import { vignettePill } from '../pills/pills';

import { bettingOverlayContainer } from './betting-style.js';

const markupNorskTipping = `<span class="nt-svg-container">
      <object aria-label="Norsk Tipping logo" type="image/svg+xml" data="https://assets.acdn.no/pkg/@amedia/brick-illustrations/1.1.1/svg/nt-logosymbol.svg"></object>
    </span>`;

export const bettingOverlayTemplate = (commercialCategory) => {
  return `<div class="${bettingOverlayContainer}">${renderBrickPillbox(
    { filled: true },
    `${vignettePill({
      text: '18+',
      filled: true,
    })}${vignettePill({
      text: 'Hjelpelinjen.no',
      filled: true,
    })}
    ${commercialCategory === 'betting_norsk_tipping' ? markupNorskTipping : ''}`
  )}
  </div>`;
};
