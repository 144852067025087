import { renderBrickButton } from '@amedia/brick-button/template';

import { Footer } from '../../types';

import { tipUsContainer, tipUsDivider, tipUsTextStyle } from './tip-us-style';

export default function tipUsTemplate(data: Footer) {
  const { tipUs, skin, marker, tipBoxText, theme, teaserHasImage } = data;

  const hasImage = teaserHasImage;

  let tipBoxHTML = '';

  if (tipUs && tipBoxText) {
    tipBoxHTML = `
    <hr class="${tipUsDivider({ theme, skin })}"></hr>
      <div class="${tipUsContainer({
        theme,
        marker,
        hasImage,
        skin,
      })}">
        <p class="${tipUsTextStyle}">${tipBoxText}</p>
        ${renderBrickButton({
          dataVersion: 'outlined',
          dataLabel: 'Tips oss',
          dataLinkto: '/vis/info/tips-oss',
          dataSize: 'small',
        })}
      </div>
      `;
  }

  return tipBoxHTML;
}
