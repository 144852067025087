import { renderBrickIcon } from '@amedia/brick-icon/template';
import { getCssText } from '@amedia/brick-tokens';

import { buttonStyle, componentStyle } from './button-styles';
import type { BrickButtonData } from './types';
import {
  kebabCase,
  mapToIconPlacement,
  mapToSize,
  mapToVersion,
} from './utils';

export interface BrickButtonTemplate extends BrickButtonData {
  isRendered?: boolean;
  inlineStyles?: boolean;
}

function btnIcon(data: BrickButtonTemplate) {
  const { dataIconid, dataIcontext, isRendered } = data;

  if (!dataIconid) {
    return '';
  }

  if (isRendered) {
    return renderBrickIcon({
      dataIconId: dataIconid,
      dataIconText: dataIcontext,
    });
  }
  return `<brick-icon-v2 data-icon-id="${dataIconid}" data-icon-text="${dataIcontext}"></brick-icon-v2>`;
}

function btnLabel(label?: string) {
  if (label) {
    return `<span>${label}</span>`;
  }
  return '';
}

function allTheClasses(data: BrickButtonData) {
  const classArray = [
    `${buttonStyle({
      version: mapToVersion(data.dataVersion),
      iconPlacement: mapToIconPlacement(data.dataIconplacement),
      size: mapToSize(data.dataSize),
    })}`,
    `${data.dataAddclasses || ''}`,
  ];
  return classArray.filter((item) => item).join(' ');
}

export const brickButtonTemplate = (data: BrickButtonTemplate): string => {
  const {
    dataAs,
    dataLinkto,
    dataLabel,
    dataDisabled,
    dataAdplabel,
    dataAdpvalue,
    isRendered,
    inlineStyles,
    ...rest
  } = data;

  const icon = data.dataIconid ? btnIcon(data) : '';
  const disabled = dataDisabled ? 'disabled' : '';

  //data-adp-clicklabel: DO NOT RENAME OR REMOVE. This is an attribute that ADP logger will register on each click.
  const adpLabelAttribute = dataAdplabel
    ? `data-adp-clicklabel="${dataAdplabel}"`
    : '';
  const adpValueAttribute = dataAdpvalue
    ? `data-adp-clickvalue="${dataAdpvalue}"`
    : '';

  const ariaAttributes = Object.keys(rest).reduce((acc, key) => {
    if (key.startsWith('dataAria')) {
      const value = rest[key];
      if (value)
        return `${acc} ${kebabCase(key.replace('data', ''))}="${value}"`;
      return acc;
    }
    return acc;
  }, '');

  const asTagIsValid = (tag: string) => ['span', 'div'].includes(tag);

  const markup = (() => {
    //dataAs overrides dataLinkto and related attributes as all we want is something that looks like a brick-button without interactivity.
    if (dataAs && asTagIsValid(dataAs)) {
      return `<${dataAs} class="${allTheClasses(data)}">${icon}${btnLabel(
        dataLabel
      )}</${dataAs}>`;
    } else {
      return dataLinkto
        ? `<a href="${dataLinkto}" target="${
            data.dataTarget
          }" ${adpLabelAttribute} ${adpValueAttribute} class="${allTheClasses(
            data
          )}" ${disabled} ${ariaAttributes}>${icon}${btnLabel(dataLabel)}</a>`
        : `<button ${adpLabelAttribute} ${adpValueAttribute} class="${allTheClasses(
            data
          )}" ${disabled} ${ariaAttributes}>
          ${icon}
          ${btnLabel(dataLabel)}
        </button>`;
    }
  })();

  // If isRendered==='true' it means the markup is rendered serverside, so we do not want to update the DOM client side.
  if (isRendered === true) {
    return `<brick-button-v7 class="${componentStyle}" is-rendered>${
      inlineStyles ? `<style>${getCssText()}</style>` : ''
    }${markup}</brick-button-v7>`;
  }

  // if not, we return the markup that should be attached to the DOM client side.
  return `
      ${markup}
    `;
};

export function renderBrickButton(data: BrickButtonTemplate) {
  const isRendered = true;
  const inlineStyles = data.inlineStyles || false;
  return brickButtonTemplate({
    ...data,
    inlineStyles,
    isRendered,
  });
}
