import { BreakingNews } from '../../types';

import { getTemplateStructure } from './templates/structure';

export function breakingNewsTemplate(data: BreakingNews) {
  const { breakingNews } = data;

  if (!breakingNews) {
    return '';
  }

  const themeStructure = getTemplateStructure(data) || '';

  return `
    ${themeStructure}
  `;
}
