import {
  renderBrickPill,
  BrickPillData,
  renderBrickPillbox,
  PillSkin,
} from '@amedia/brick-pill/template';
import { Design } from '@amedia/brick-tokens';

import { Countdown, BreakingNews } from '../../types';

interface BreakingPill {
  breakingNews?: BreakingNews;
  theme: Design;
  filled?: boolean;
  skin: PillSkin;
}

export interface PillData extends BrickPillData {
  theme?: Design;
  countdown?: Countdown | null;
  text?: string;
  version?: string;
  skin?: PillSkin;
  filled?: boolean;
}

export const breakingPill = ({
  breakingNews,
  filled = false,
  skin,
}: BreakingPill) => {
  if (!breakingNews || !breakingNews.breakingNews) {
    return '';
  }
  const { breakingNewsPosition, breakingNewsText = '' } = breakingNews;
  const text = breakingNewsText || '';

  if (breakingNewsPosition === 'title' && text === '') {
    return '';
  }

  return renderBrickPill({
    text,
    version: 'breaking',
    filled,
    skin,
    ...(!text && {
      iconText: 'Nyhetsvarsel',
    }),
  });
};

export const videoPill = (data: PillData) => {
  const { text = 'Video', version, skin, filled = false } = data;
  if (version !== 'video') {
    return '';
  }
  return renderBrickPill({
    text,
    version,
    filled,
    skin,
  });
};

export const galleryPill = (data: PillData) => {
  const { text = 'Bildeserie', version, skin, filled = false } = data;
  if (version === 'gallery') {
    return renderBrickPill({
      text,
      version,
      filled,
      skin,
    });
  }
  return '';
};
export const galleryPillTemplateV1 = (data: PillData) => {
  const { text = 'BILDESERIE', version, skin, filled = false } = data;
  if (version === 'gallery') {
    return renderBrickPill({
      text,
      version,
      skin,
      filled,
    });
  }
  return '';
};

export const plusPill = (data: PillData) => {
  const { text = '', version, skin, filled = false } = data;
  if (version === 'plus' || version === 'plusall') {
    return `${renderBrickPill({
      text,
      version,
      filled,
      skin,
      iconText: 'Artikkelen er for abonnenter.',
    })}`;
  }
  return '';
};

export const vignettePill = (data: PillData) => {
  const { text, skin, filled = false } = data;

  if (!text) {
    return '';
  }

  if (!!text && text !== 'null') {
    return `${renderBrickPill({
      text,
      version: 'vignette',
      filled,
      skin,
    })}`;
  }
  return '';
};

export const countdownPill = (data: PillData) => {
  const { text = '', filled = false, skin, countdown } = data;
  if (!countdown?.date || countdown?.position === 'none') {
    return '';
  }

  return renderBrickPill({
    text,
    version: 'countdown',
    filled,
    skin,
    countdownDate: countdown.date,
    ...(countdown.toggleVersion && {
      countdownToggleText: countdown.toggleText || '',
      countdownToggleVersion: countdown.toggleVersion,
    }),
  });
};

export const ghostPill = (data: PillData) => {
  const { text = '', skin, filled = true, version } = data;

  const renderAltPill = renderBrickPill({
    text,
    version: version === 'alt' ? 'alt' : 'untold',
    filled,
    skin,
  });

  const renderPublicationPill = renderBrickPill({
    text,
    version: 'vignette',
    filled,
    skin,
  });

  if (text) {
    return renderBrickPillbox(
      { filled },
      `${renderAltPill}${renderPublicationPill}`
    );
  } else {
    return renderBrickPillbox({ filled }, renderAltPill);
  }
};
