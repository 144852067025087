import { Countdown, CountdownApi } from '../types';

export function getCountdowns(countdownData?: CountdownApi | null): {
  countdown?: Countdown;
} {
  let countdownDate;

  if (countdownData?.dateTime) {
    countdownDate = new Date(countdownData.dateTime).valueOf();
  }

  if (typeof countdownDate !== 'number') {
    return {};
  }

  const countdown: Countdown | undefined = countdownData
    ? {
        position: countdownData.position || 'none',
        date: countdownDate,
        ...(countdownData.toggleVersion && {
          toggleText: countdownData.toggleText || '',
          toggleVersion: countdownData.toggleVersion,
        }),
        text: countdownData.leadText || '',
      }
    : undefined;

  return { countdown };
}
