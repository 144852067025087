import { renderBrickPillbox } from '@amedia/brick-pill/template';

import { vignettePill, breakingPill, countdownPill } from '../../pills/pills';
import { OverlayPills } from '../../../types';
import { mapToPillVersion } from '../../../utils/mapToPillVersion';
import { renderStrings } from '../../../utils/renderStrings';
import { mapToPillSkin } from '../../../utils/getSkin';

export default function pillBoxTemplate(data: OverlayPills) {
  const {
    teaserType,
    theme = 'bravo',
    breakingNews,
    countdown,
    vignette,
    marker,
    skin,
    commercialCategory,
  } = data;

  let pillSkin = mapToPillSkin({ skin, commercialCategory });
  if (teaserType === 'opinion' && !marker) {
    pillSkin = 'opinion';
  }

  const filled = teaserType === 'feature' ? false : true;

  const opinionLabel =
    teaserType === 'opinion'
      ? vignette?.opinionTypeDisplay || vignette?.vignette
      : undefined;
  const vignetteText =
    vignette?.vignette !== opinionLabel ? vignette?.vignette : undefined;

  const isCountdownPill =
    !!countdown?.position &&
    !!countdown?.date &&
    countdown?.position !== 'bottom';

  const isBreaking =
    (breakingNews?.breakingNews &&
      breakingNews?.breakingNewsPosition === 'vignette') ||
    false;
  const pillVersion = mapToPillVersion(teaserType);

  const bodyPillBox = {
    breaking: '',
    opinion: '',
    vignette: '',
    countdown: '',
    ...(isBreaking && {
      breaking: breakingPill({
        breakingNews,
        skin: pillSkin,
        theme,
        filled,
      }),
    }),
    ...(isCountdownPill && {
      countdown: countdownPill({
        version: pillVersion,
        countdown,
        skin: pillSkin,
        theme,
        filled,
      }),
    }),
    ...(opinionLabel && {
      opinion: vignettePill({
        version: pillVersion,
        text: opinionLabel,
        skin: pillSkin,
        filled,
      }),
    }),
    ...(vignetteText && {
      vignette: vignettePill({
        version: pillVersion,
        text: vignetteText,
        skin: pillSkin,
        filled,
      }),
    }),
  };

  const pillBoxChildren = renderStrings`${bodyPillBox.breaking} ${bodyPillBox.opinion} ${bodyPillBox.countdown} ${bodyPillBox.vignette} `;

  return `${renderBrickPillbox({ filled }, pillBoxChildren)}`;
}
