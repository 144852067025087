import { renderBrickBreakingNewsText } from '../../breaking-text/template';
import { BreakingNews } from '../../../types';

function disableSonar(position: string, text?: string) {
  if (text && !position) {
    return true;
  }
  if (!position) {
    return true;
  }

  return false;
}

export function getTemplateStructure(data: BreakingNews) {
  const {
    breakingNewsPosition = '',
    breakingNewsColor = 'red',
    breakingNewsText,
    theme,
    skin,
  } = data;

  const breakingPosition = breakingNewsPosition || 'title';

  const showSonar = disableSonar(breakingNewsPosition, breakingNewsText);

  const position = breakingPosition;

  const titleStructureTemplate = {
    default: [
      `${renderBrickBreakingNewsText({
        text: breakingNewsText,
        color: breakingNewsColor,
        disableSonar: showSonar,
        theme,
        skin,
      })}`,
    ],
  };

  const vignetteStructureTemplate = {
    default: [],
  };

  const structureTemplatePosition = {
    vignette: vignetteStructureTemplate,
    title: titleStructureTemplate,
    default: titleStructureTemplate,
  };

  const structureTemplate =
    structureTemplatePosition[position] || structureTemplatePosition.title;

  return (
    (structureTemplate && structureTemplate[theme]) ||
    structureTemplate.default
  ).join('');

  //if !breakingNews return

  //if breakingNewsText
  // - position title template pr theme
  // - position vignette template pr theme
  // - position none none template pr theme

  //if breakingNews
  // - position title template
  // - position vignette template
  // - position 'none'
}
