import { css } from '@amedia/brick-tokens';

export const imageFeatureLayoutStyle = css({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto',
  gridAutoRows: '1fr auto',
  gridTemplateAreas: '"image" "title"',
  variants: {
    premium: {
      true: { gridTemplateAreas: '"image image" "title title" ". premium"' },
      false: { gridTemplateAreas: '"image" "title"' },
    },
  },
});

export const noImageFeatureLayoutStyle = css({
  '--_b-feature-padding':
    'var(--brick-space-teaserContentYInsetM) var(--brick-space-teaserContentXInsetM)',
  display: 'grid',
  gridTemplateRows: 'auto',
  gridAutoRows: '1fr auto',
  rowGap: '$teaserStackBM',
  columnGap: '$teaserStackBM',
  gridTemplateColumns: '1fr',

  padding: 'var(--b-teaser-padding-noImageContent, var(--_b-feature-padding))',
  '@bp532': {
    '--_b-feature-padding':
      'var(--brick-space-teaserContentYInsetL) var(--brick-space-teaserContentXInsetL)',
    rowGap: '$teaserStackBL',
    columnGap: '$teaserStackBL',
  },

  variants: {
    theme: {
      alfa: {},
      bravo: {},
      charlie: {},
      nettavisen: {},
      alt: {},
    },
    pills: {
      true: {},
      false: {},
    },
    premium: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      pills: false,
      premium: false,
      css: {
        gridTemplateAreas: '"title"',
      },
    },
    {
      pills: true,
      premium: false,
      css: {
        gridTemplateAreas: '"pills" "title"',
      },
    },
    {
      pills: false,
      premium: true,
      theme: 'alfa',
      css: {
        gridTemplateAreas: '"title premium"',
      },
    },
    {
      pills: true,
      premium: true,
      theme: 'alfa',
      css: {
        gridTemplateAreas: '"pills ." "title premium"',
      },
    },
    {
      pills: false,
      premium: true,
      theme: 'bravo',
      css: {
        gridTemplateAreas: '"title title" ". premium"',
      },
    },
    {
      pills: true,
      premium: true,
      theme: 'bravo',
      css: {
        gridTemplateAreas: '"pills pills" "title title" ". premium"',
      },
    },
    {
      pills: false,
      premium: true,
      theme: 'charlie',
      css: {
        gridTemplateAreas: '"title premium"',
      },
    },
    {
      pills: true,
      premium: true,
      theme: 'charlie',
      css: {
        gridTemplateAreas: '"pills ." "title premium"',
      },
    },
    {
      pills: false,
      theme: 'nettavisen',
      css: {
        gridTemplateAreas: '"title"',
      },
    },
    {
      pills: true,
      theme: 'nettavisen',
      css: {
        gridTemplateAreas: '"pills" "title"',
      },
    },
  ],
});

export const featureStyle = css({
  container: 'teaser-content / inline-size',
  color: 'inherit',
});

export const pillContainer = css({
  '--_b-feature-pill-padding': '0 0 2px 0',
  padding: 'var(--b-teaser-padding-pill-wrap, var(--_b-feature-pill-padding))',

  '@bp532': {
    '--_b-feature-pill-padding': '0 0 2px 0',
  },
  variants: {
    theme: {
      alfa: {},
      bravo: {},
      charlie: {},
      nettavisen: {},
      alt: {},
    },
    hasImage: {
      true: {
        gridArea: 'image',
        zIndex: '2',
        alignSelf: 'end',
      },
      false: { gridArea: 'pills' },
    },
  },
  compoundVariants: [
    {
      theme: 'alfa',
      hasImage: true,
      css: {
        '--_b-feature-pill-padding': '0 0 2px var(--brick-space-teaserXInsetM)',
        '@bp532': {
          '--_b-feature-pill-padding':
            '0 0 2px var(--brick-space-teaserXInsetL)',
        },
      },
    },
    {
      theme: 'bravo',
      hasImage: true,
      css: {
        '--_b-feature-pill-padding':
          'var(--brick-space-teaserContentYInsetM) 0 2px var(--brick-space-teaserContentXInsetM)',
        '@bp532': {
          '--_b-feature-pill-padding':
            'var(--brick-space-teaserContentYInsetL) 0 2px var(--brick-space-teaserContentXInsetL)',
        },
      },
    },
    {
      theme: 'charlie',
      hasImage: true,
      css: {
        '--_b-feature-pill-padding': '0 0 2px var(--brick-space-teaserXInsetM)',
        '@bp532': {
          '--_b-feature-pill-padding':
            '0 0 2px var(--brick-space-teaserXInsetL)',
        },
      },
    },
    {
      theme: 'nettavisen',
      hasImage: true,
      css: {
        '--_b-feature-pill-padding':
          'var(--brick-space-teaserSkinInsetM) 0 2px var(--brick-space-teaserSkinInsetM)',
        '@bp532': {
          '--_b-feature-pill-padding':
            'var(--brick-space-teaserSkinInsetL) 0 2px var(--brick-space-teaserSkinInsetL)',
        },
      },
    },
  ],
});

export const titleContainer = css({
  '--_b-feature-title-padding-top': '6px',
  '--_b-feature-title-padding-x': 'var(--brick-space-teaserXInsetM)',
  '--_b-feature-title-padding-bottom': 'var(--brick-space-teaserYInsetM)',

  gridArea: 'title',
  display: 'flex',
  flexDirection: 'column',

  gap: '$teaserStackTitleM',
  '@bp532': {
    gap: '$teaserStackTitleL',
    '--_b-feature-title-padding-x': 'var(--brick-space-teaserXInsetL)',
    '--_b-feature-title-padding-bottom': 'var(--brick-space-teaserYInsetL)',
  },
  variants: {
    theme: {
      alfa: {},
      bravo: {
        '--_b-feature-title-padding-x':
          'var(--brick-space-teaserContentXInsetM)',
        '@bp532': {
          '--_b-feature-title-padding-x':
            'var(--brick-space-teaserContentXInsetL)',
        },
      },
      charlie: {},
      nettavisen: {
        '@bp532': {
          '--_b-feature-title-padding-x': 'var(--brick-space-teaserSkinInsetL)',
        },
      },
      alt: {},
    },
    hasImage: {
      true: {
        paddingTop:
          'var(--b-teaser-padding-content-top, var(--_b-feature-title-padding-top))',
        paddingLeft:
          'var(--b-teaser-padding-content-left ,var(--_b-feature-title-padding-x))',
        paddingRight:
          'var(--b-teaser-padding-content-right ,var(--_b-feature-title-padding-x))',
      },
      false: {},
    },
    premium: {
      true: {},
      false: { paddingBottom: 'var(--_b-feature-title-padding-bottom)' },
    },
  },
  compoundVariants: [
    {
      hasImage: true,
      theme: 'alfa',
      css: {
        '--_b-feature-title-padding-bottom':
          'calc(var(--brick-space-teaserYInsetM) * 2)',
      },
    },
    {
      hasImage: false,
      theme: 'alfa',
      css: {
        '--_b-feature-title-padding-bottom': 'var(--brick-space-teaserYInsetM)',
      },
    },
    {
      hasImage: true,
      theme: 'bravo',
      css: {
        '--_b-feature-title-padding-bottom':
          'calc(var(--brick-space-teaserContentYInsetM) * 2)',
      },
    },
    {
      hasImage: false,
      theme: 'bravo',
      css: {
        '--_b-feature-title-padding-bottom':
          'var(--brick-space-teaserContentYInsetM)',
      },
    },
    {
      hasImage: true,
      theme: 'charlie',
      css: {
        '--_b-feature-title-padding-bottom': 'var(--brick-space-teaserYInsetM)',
      },
    },
    {
      hasImage: false,
      theme: 'charlie',
      css: {
        '--_b-feature-title-padding-bottom': 0,
      },
    },
    {
      hasImage: true,
      theme: 'nettavisen',
      css: {
        '--_b-feature-title-padding-bottom':
          'calc(var(--brick-space-teaserSkinInsetM) * 2)',
        '@bp532': {
          '--_b-feature-title-padding-bottom':
            'calc(var(--brick-space-teaserSkinInsetL) * 2)',
        },
      },
    },
    {
      hasImage: false,
      theme: 'nettavisen',
      css: {
        '--_b-feature-title-padding-bottom': 0,
        '@bp532': {
          '--_b-feature-title-padding-bottom': 0,
        },
      },
    },
  ],
});

export const premiumWrapper = css({
  '--_b-feature-premium-padding':
    'var(--brick-space-teaserStackBM) var(--brick-space-teaserXInsetM) var(--brick-space-teaserYInsetM) 0',
  '@bp532': {
    '--_b-feature-premium-padding':
      'var(--brick-space-teaserStackBL) var(--brick-space-teaserXInsetL) var(--brick-space-teaserYInsetL) 0',
  },
  gridArea: 'premium',
  alignSelf: 'end',
  display: 'flex',
  variants: {
    theme: {
      alfa: {
        '.skin & .premium-icon': {
          padding: '2px',
          '@bp532': { padding: '3px' },
        },
      },
      bravo: {},
      charlie: {},
      nettavisen: {
        '.skin & .premium-icon': {
          padding: '2px',
          '@bp532': { padding: '3px' },
        },
      },
      alt: {},
    },
    hasImage: {
      true: {
        padding:
          'var(--b-teaser-padding-premium-wrap, var(--_b-feature-premium-padding))',
      },
      false: {},
    },
  },
  compoundVariants: [
    {
      theme: 'bravo',
      hasImage: true,
      css: {
        '--_b-feature-premium-padding':
          'var(--brick-space-teaserStackCM) var(--brick-space-teaserContentXInsetM) var(--brick-space-teaserContentYInsetM) 0',
        '@bp532': {
          '--_b-feature-premium-padding':
            'var(--brick-space-teaserStackCL) var(--brick-space-teaserContentXInsetL) var(--brick-space-teaserContentYInsetL) 0',
        },
      },
    },
    {
      theme: 'nettavisen',
      hasImage: true,
      css: {
        '@bp532': {
          '--_b-feature-premium-padding':
            'var(--brick-space-teaserStackBL) var(--brick-space-teaserSkinInsetL) 0.4375rem 0',
        },
      },
    },
  ],
});
export const imageWrapper = css({
  gridArea: 'image',
  position: 'relative',

  //TODO: feature border radius token
  '& .imagewrapper': {
    borderRadius:
      'var(--b-teaser-borderRadius-image, var(--brick-radii-teaser)) !important',
  },
});

export const featureGradientStyle = css({
  position: 'absolute',
  bottom: '0px',
  left: '0px',
  right: '0px',
  height: '100%',
  pointerEvents: 'none',

  variants: {
    layer: {
      one: {
        minHeight: '90px',
        height: 'var(--brick-teaser-overlay-container-height)',
        background:
          'linear-gradient(10deg, currentColor, transparent, 73%, transparent )',
      },
      two: {
        minHeight: '80px',
        height: 'calc(var(--brick-teaser-overlay-container-height) * 1.6)',
        background:
          'linear-gradient(3deg, currentColor, transparent, 65%, transparent )',
      },
      three: {
        minHeight: '70px',
        height: 'var(--brick-teaser-overlay-container-height)',
        background:
          'linear-gradient(0deg, currentColor, transparent, 43%, transparent )',
      },
      four: {
        minHeight: '75px',
        height: 'calc(var(--brick-teaser-overlay-container-height) * 1.6)',
        background:
          'linear-gradient(-3deg, currentColor, transparent, 65%, transparent )',
      },
    },
  },
});
