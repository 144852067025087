// import type { Title, VideoMeta } from '../../../types.js';
import { playVideoStyle } from '../teaser-style.js';

export const playerTemplate = ({ title, videoMeta }) => {
  if (!videoMeta.videoId) {
    return null;
  }
  const aspectRatioAttribute = videoMeta.aspectRatio
    ? `aspectratio="${videoMeta.aspectRatio}"`
    : '';
  const playerIdAttribute = videoMeta.playerId
    ? `playerid="${videoMeta.playerId}"`
    : '';

  return `<article class="${playVideoStyle}">
  <brick-player 
  mediaid="${videoMeta.videoId}" 
  ${playerIdAttribute}
  ${aspectRatioAttribute}
  title="${title?.title}"
  autoplay="false" 
  muted="false" 
  teasertitle="true" 
  adsdisabled="false" 
  >
  </brick-player>
  </article>`;
};
