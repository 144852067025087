import { imageStyle } from './style.js';
import type { BrickImageTemplate } from './types';

const withHttps = (url) =>
  !/^(https?:\/\/|https?%3A%2F%2F)/i.test(url) ? `https:${url}` : url;

export function brickImageSrcset({
  srcsizes,
  url,
  graphicsHost = 'g.acdn.no',
  encodeUri = true,
}) {
  if (!url) {
    return;
  }

  const defaultSizes = [480, 680, 880, 1080, 1280];
  const sizeslist =
    srcsizes.split(',').map((item) => item.trim()) || defaultSizes;

  const srcset = sizeslist
    .map((size) => {
      let resizedUrl = `https://${graphicsHost}/api/reflex/v1/image/resize/${size}`;

      if (encodeUri) {
        resizedUrl = `${resizedUrl}/${encodeURIComponent(
          withHttps(url.trim())
        )}`;
      } else {
        resizedUrl = `${resizedUrl}/${withHttps(url.trim())}`;
      }
      const sizeSplit = size.split('_');
      return `${resizedUrl} ${sizeSplit[0]}w`;
    })
    .join(', ');
  return srcset;
}

export function brickImageSrc({
  srcsize,
  url,
  graphicsHost,
  encodeUri = true,
}) {
  const trimmedUrl = url.trim();
  const resizedUrl = `https://${graphicsHost}/api/reflex/v1/image/resize/${srcsize}`;

  if (encodeUri) {
    return `${resizedUrl}/${encodeURIComponent(trimmedUrl)}`;
  } else {
    return `${resizedUrl}/${trimmedUrl}`;
  }
}

const validateString = (value) => {
  if (typeof value === 'string' && value === 'undefined') {
    return null;
  }
  return value;
};

export function brickImageTemplate(data: BrickImageTemplate) {
  const {
    dataSrc = '',
    dataSrcsize,
    dataSrcset = '',
    dataAltText = '',
    dataSizes,
    dataFetchPriority = 'auto',
    dataLoading = 'auto',
    dataWidth = '',
    dataHeight = '',
    graphicsHost = 'g.acdn.no',
    dataEncodeUri = 'true',
  } = data;
  let srcset = '';

  if (dataSrcset) {
    srcset = dataSrcset;
  }
  const encodeUri = dataEncodeUri === 'true';

  if (!dataSrcset && dataSrc) {
    srcset = brickImageSrcset({
      url: dataSrc,
      srcsizes: '280,480,680,880,1080,1280,1680',
      graphicsHost,
      encodeUri,
    });
  }

  if (data.isRendered) {
    const ssrsrc = dataSrc ? `data-src="${dataSrc}"` : '';
    const ssrsrcsize = dataSrc ? `data-srcsize="${dataSrcsize}"` : '';
    const ssrsrcset = dataSrcset ? `data-srcset="${srcset}"` : '';
    const sizes = dataSizes ? `data-sizes="${dataSizes}"` : '';
    const lazyload = `data-loading="${dataLoading}"`;
    const priority = dataFetchPriority
      ? `data-fetch-priority="${dataFetchPriority}"`
      : '';
    const altText = dataAltText ? `data-alt-text="${dataAltText}"` : '';
    const encodeuri = encodeUri ? `data-encode-uri="${encodeUri}"` : '';
    const width = validateString(dataWidth) ? `data-width="${dataWidth}"` : '';
    const height = validateString(dataHeight)
      ? `data-height="${dataHeight}"`
      : '';
    return `<brick-image-v${data.version} ${width} ${height} ${ssrsrcsize} ${lazyload} ${priority} ${encodeuri} ${ssrsrc} ${sizes} ${ssrsrcset} ${altText}" ></brick-image-v${data.version}>`;
  }
  const imgsrc = dataSrc
    ? `${brickImageSrc({
        srcsize: dataSrcsize || 480,
        url: dataSrc,
        graphicsHost,
        encodeUri,
      })}`
    : '';

  const ratio = `${validateString(dataWidth)} / ${validateString(dataHeight)}`;
  const ratioVar =
    validateString(dataWidth) && validateString(dataHeight)
      ? `--brick-image-ratio: ${ratio};`
      : '';

  return `<img 
  width="${validateString(dataWidth) || ''}"
  height="${validateString(dataHeight || '')}"
  class="${imageStyle}" 
  fetchpriority="${dataFetchPriority}"
  sizes="${dataSizes}" 
  loading="${dataLoading}" 
  srcset="${srcset}"
  src="${imgsrc}" 
  class="${imageStyle}" 
  alt="${dataAltText}" 
  style="${ratioVar}"
  
  />`;
}

export function renderBrickImage(data: BrickImageTemplate) {
  data.isRendered = true;
  data.version = '3';
  return brickImageTemplate(data);
}
