export type BreakingSkin =
  | 'none'
  | 'black'
  | 'custom-one'
  | 'custom-two'
  | 'highlight'
  | 'sport';

const colorThemes: BreakingSkin[] = [
  'none',
  'black',
  'custom-one',
  'custom-two',
  'highlight',
  'sport',
];
export function mapToBreakingSkin(value?: string): BreakingSkin {
  if (colorThemes.includes(value as BreakingSkin)) {
    return value as BreakingSkin;
  } else {
    return 'none';
  }
}
