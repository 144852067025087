import { renderBrickPillbox } from '@amedia/brick-pill/template';

import { BodyPills, PremiumVersion } from '../../../types';
import { mapToPillVersion } from '../../../utils/mapToPillVersion';
import {
  videoPill,
  galleryPill,
  plusPill,
  vignettePill,
} from '../../pills/pills';
import { pillContainer } from '../body-style.js';

export default function bottomPillsTemplate(
  data: BodyPills,
  premiumVersion?: PremiumVersion
) {
  const { teaserType, theme, videoMeta, marker = false, skin, text } = data;
  const isPremium = Boolean(premiumVersion);

  const renderPills =
    teaserType === 'video' || teaserType === 'gallery' || isPremium;
  const filled = marker;
  const pillVersion = mapToPillVersion(teaserType);

  const videoPillText = videoMeta?.videoDuration ? videoMeta.videoDuration : '';

  const pills = `
  ${videoPill({
    version: pillVersion,
    skin,
    theme,
    filled,
    text,
  })}
  ${vignettePill({
    version: 'vignette',
    text: videoPillText,
    skin,
    theme,
    filled,
  })}
  ${galleryPill({
    version: pillVersion,
    text,
    skin,
    theme,
    filled,
  })}
  `;

  return renderPills
    ? `<div class="${pillContainer({
        marker,
        pills: !!pills.trim(),
        theme,
      })}">
  ${renderBrickPillbox({ filled }, pills)}
  ${
    isPremium
      ? plusPill({
          version: premiumVersion,
          skin: skin,
          theme,
        })
      : ''
  } 
</div>`
    : '';
}
