import { pillBoxStyle } from '../brick-pillbox-styles';
import type { PillboxData } from '../types.js';

export function pillboxTemplate(
  data: PillboxData,
  isServerSide: boolean,
  children?: string
) {
  if (!data || !Object.entries(data).length) {
    return '';
  }

  if (!children || !Object.entries(children).length) {
    return '';
  }

  const styles = `${pillBoxStyle({
    filled: data.filled,
  })}`;
  const filledAttribute = data.filled ? 'data-filled="true"' : '';

  if (isServerSide) {
    return `<brick-pillbox is-rendered ${filledAttribute} class="${styles}">${
      children || ''
    }</brick-pillbox>`;
  }

  return '';
}

export function renderBrickPillbox(
  pillboxData: PillboxData,
  children?: string
) {
  const markup = pillboxTemplate(pillboxData, true, children);

  return markup;
}
