import { TeaserType, ImageData, VideoData } from '../types';

import { getVideoPreview } from './getVideoPreview';

function getAoiWidth(imgAoi): string {
  if (imgAoi) {
    return Number.isNaN(imgAoi.widthPercentage) ? '' : imgAoi.widthPercentage;
  }

  return '';
}

function getAutolayoutImage(
  imageData: ImageData,
  teaserType: TeaserType,
  aoiwidth: string
) {
  return `<amedia-img type='${teaserType}' aoiwidth='${aoiwidth}' alt="" srcset='${imageData.srcset}'></amedia-img>`;
}

function getBrickImage(imageData: ImageData) {
  const imageSrc = imageData.url ? `data-src="${imageData.url}"` : '';
  const imageSizes = imageData.sizes ? `data-sizes="${imageData.sizes}"` : '';
  const imageSrcset = imageData.srcset
    ? `data-srcset="${imageData.srcset}"`
    : '';
  const encodeUri =
    imageData.encodeUri !== undefined
      ? `data-encode-uri="${imageData.encodeUri}"`
      : '';

  return `<brick-image-v3 data-loading="lazy" ${imageSrc} ${imageSrcset} ${encodeUri} data-alt-text="" ${imageSizes} data-height="${imageData.height}" data-width="${imageData.width}"></brick-image-v3>`;
}

export function getImageTag({
  imageData,
  title,
  useAutolayout,
  teaserType,
}: {
  imageData: ImageData;
  title: string;
  useAutolayout?: boolean;
  teaserType?: TeaserType;
  videoPreview?: VideoData;
}) {
  const aoiwidth = getAoiWidth(imageData.imgAoi);
  const imageElement =
    useAutolayout && teaserType
      ? getAutolayoutImage(imageData, teaserType, aoiwidth)
      : getBrickImage(imageData);

  if (imageData.preview || imageData.video) {
    const url = imageData.video?.url;
    return getVideoPreview({
      url: url,
      previewUrl: imageData.preview,
      title,
      imageElement,
    });
  }

  return imageElement;
}
