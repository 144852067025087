import type { Design } from '@amedia/brick-tokens';

export const colorMapping = {
  black: 'background-black',
  yellow: 'background-yellow',
  customOne: 'background-custom custom-one',
  customTwo: 'background-custom custom-two',
  customThree: 'background-custom custom-three',
  opinion: 'background-opinion',
};

export const mapToBgColor = ({
  color,
  theme,
  isBreaking,
}: {
  color: string;
  theme?: Design;
  isBreaking?: boolean;
}) => {
  if (!color) {
    return '';
  }

  const bgColor = colorMapping[color] || color;

  if (theme === 'nettavisen' && isBreaking && bgColor === 'background-black') {
    return 'background-yellow';
  }

  return bgColor;
};
