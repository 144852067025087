import { css } from '../../styles';

export const tipUsDivider = css({
  '--_b-teaser-color-divider': 'currentColor',
  '--_b-teaser-margin-divider': 0,
  borderColor: 'var(--b-teaser-color-divider, var(--_b-teaser-color-divider))',
  unset: 'all',
  padding: '0',
  margin: 'var(--b-teaser-margin-divider, var(--_b-teaser-margin-divider))',
  borderStyle: 'none',
  alignSelf: 'flex-start',
  display: 'flex',
  flex: '1 1 100%',
  borderWidth: '1.3px',
  borderBottomStyle: 'solid',
  borderTop: 'none',
  borderLeft: 'none',
  borderRight: 'none',
  opacity: '0.65',
  variants: {
    theme: {
      alt: {},
      alfa: {},
      bravo: {
        '--_b-teaser-margin-divider':
          '0 $teaserFooterTipusRightM 0 $teaserFooterTipusLeftM',
        '@bp532': {
          '--_b-teaser-margin-divider':
            '0 $teaserFooterTipusRightL 0 $teaserFooterTipusLeftL',
        },
      },
      charlie: {},
      nettavisen: {},
    },
    skin: {
      sport: {},
      none: {},
      black: {},
      'custom-one': {},
      'custom-two': {},
      'custom-three': {},
      highlight: {},
      opinion: {},
    },
  },
  compoundVariants: [
    {
      theme: 'alfa',
      skin: 'none',
      css: {
        '--_b-teaser-color-divider': 'currentColor',
      },
    },
    {
      theme: 'alfa',
      skin: 'black',
      css: {
        '--_b-teaser-color-divider': '$supportiveBlackFg',
      },
    },
    {
      theme: 'alfa',
      skin: 'highlight',
      css: {
        '--_b-teaser-color-divider': '$supportiveHighlightDivider',
        opacity: '100%',
      },
    },
    {
      theme: 'alfa',
      skin: 'custom-one',
      css: {
        '--_b-teaser-color-divider': `var(--custom-background-color-one-front, $colors$supportiveCustomOneFg)`,
      },
    },
    {
      theme: 'alfa',
      skin: 'custom-two',
      css: {
        '--_b-teaser-color-divider': 'currentColor',
      },
    },
    {
      theme: 'bravo',
      skin: 'none',
      css: {
        '--_b-teaser-color-divider': 'transparent',
      },
    },
    {
      theme: 'bravo',
      skin: 'black',
      css: {
        '--_b-teaser-color-divider': 'transparent',
      },
    },
    {
      theme: 'bravo',
      skin: 'highlight',
      css: {
        '--_b-teaser-color-divider': '$supportiveHighlightFg',
        paddingTop: '$teaserFooterTipusTopM',
      },
    },
    {
      theme: 'bravo',
      skin: 'custom-one',
      css: {
        '--_b-teaser-color-divider': `var(--custom-background-color-one-front, $colors$supportiveCustomOneFg)`,
        paddingTop: '$teaserFooterTipusTopM',
      },
    },
    {
      theme: 'bravo',
      skin: 'custom-two',
      css: {
        '--_b-teaser-color-divider': `var(--custom-background-color-two-front, $supportiveCustomTwoFg)`,
        paddingTop: '$teaserFooterTipusTopM',
      },
    },
    {
      theme: 'bravo',
      skin: 'custom-three',
      css: {
        '--_b-teaser-color-divider': `var(--custom-background-color-three-front, $supportiveCustomThreeFg)`,
        paddingTop: '$teaserFooterTipusTopM',
      },
    },
    {
      theme: 'charlie',
      skin: 'none',
      css: {
        '--_b-teaser-color-divider': 'currentColor',
      },
    },
    {
      theme: 'charlie',
      skin: 'black',
      css: {
        '--_b-teaser-color-divider': '$supportiveBlackFg',
      },
    },
    {
      theme: 'charlie',
      skin: 'highlight',
      css: {
        '--_b-teaser-color-divider': '$supportiveHighlightDivider',
      },
    },
    {
      theme: 'charlie',
      skin: 'custom-one',
      css: {
        '--_b-teaser-color-divider': 'currentcolor',
      },
    },
    {
      theme: 'charlie',
      skin: 'custom-two',
      css: {
        '--_b-teaser-color-divider': 'currentcolor',
      },
    },
    {
      theme: 'nettavisen',
      skin: 'black',
      css: {
        '--_b-teaser-color-divider': 'transparent',
      },
    },
    {
      theme: 'nettavisen',
      skin: 'highlight',
      css: {
        '--_b-teaser-color-divider': '$supportiveHighlightDivider',
        opacity: '100%',
      },
    },
    {
      theme: 'nettavisen',
      skin: 'black',
      css: {
        '--_b-teaser-color-divider': '$supportiveBlackFg',
      },
    },
    {
      theme: 'nettavisen',
      skin: 'custom-one',
      css: {
        '--_b-teaser-color-divider': 'transparent',
      },
    },
    {
      theme: 'nettavisen',
      skin: 'custom-two',
      css: {
        '--_b-teaser-color-divider': 'transparent',
      },
    },
  ],
  defaultVariants: {
    theme: 'alfa',
  },
});

const unsetPadding = {
  '--_b-footer-content-padding': 0,
};

export const tipUsContainer = css({
  '--_b-footer-content-padding-top': 'var(--brick-space-teaserFooterTipusTopM)',
  '--_b-footer-content-padding':
    'var(--_b-footer-content-padding-top) var(--brick-space-teaserFooterTipusRightM) var(--brick-space-teaserFooterTipusBottomM) var(--brick-space-teaserFooterTipusLeftM)',

  padding:
    'var(--b-teaser-padding-footer-content, var(--_b-footer-content-padding))',
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  alignItems: 'center',

  '@bp532': {
    '--_b-footer-content-padding-top':
      'var(--brick-space-teaserFooterTipusTopL)',
    '--_b-footer-content-padding':
      'var(--_b-footer-content-padding-top) var(--brick-space-teaserFooterTipusRightL) var(--brick-space-teaserFooterTipusBottomL) var(--brick-space-teaserFooterTipusLeftL)',
  },

  '& brick-button-v7 > a': {
    color: '$buttonOutlinedFg !important',
    whiteSpace: 'nowrap',
    flex: '0 0 auto',
  },

  variants: {
    theme: {
      alt: {},
      alfa: {},
      bravo: {},
      charlie: {},
      nettavisen: {},
    },
    skin: {
      sport: {},
      none: {},
      black: {},
      'custom-one': {},
      'custom-two': {},
      'custom-three': {},
      highlight: {},
      opinion: {},
    },
    marker: {
      true: {},
      false: {},
    },
    hasImage: {
      true: {},
      false: {},
    },
  },

  compoundVariants: [
    {
      theme: 'bravo',
      skin: 'none',
      css: {
        ...unsetPadding,
      },
    },
    {
      theme: 'bravo',
      skin: 'black',
      css: {
        ...unsetPadding,
      },
    },
    {
      theme: 'nettavisen',
      skin: 'black',
      css: {
        '--_b-footer-content-padding':
          'var(--_b-footer-content-padding-top) 0 0 0',
      },
    },
    {
      theme: 'nettavisen',
      skin: 'highlight',
      css: {
        '--_b-footer-content-padding':
          'var(--_b-footer-content-padding-top) 0 0 0',
      },
    },
    {
      theme: 'nettavisen',
      skin: 'custom-one',
      css: {
        ...unsetPadding,
      },
    },
    {
      theme: 'nettavisen',
      skin: 'custom-two',
      css: {
        ...unsetPadding,
      },
    },
    {
      theme: 'nettavisen',
      skin: 'custom-three',
      css: {
        '--_b-footer-content-padding':
          'var(--_b-footer-content-padding-top) 0 0 0',
      },
    },
    {
      theme: 'nettavisen',
      marker: true,
      hasImage: false,
      css: {
        '@bp532': {
          gap: 'calc(2 * $teaserStackBL)',
          display: 'flex',
          flexWrap: 'wrap',
        },
      },
    },
  ],
});

export const tipUsTextStyle = css({
  fontstyle: 'tipusM',
  margin: 0,
  padding: 0,
  flex: '1 0 0%',
  minWidth: '55%',
});
