import { css } from '../../styles';

const customOneDefaultColors = {
  backgroundColor:
    'var(--custom-background-color-one, $colors$teaserFooterCustomOneBg)',
  color: 'var(--custom-background-color-one-front, $supportiveCustomOneFg)',
};

const customTwoDefaultColors = {
  backgroundColor:
    'var(--custom-background-color-two ,$colors$teaserFooterCustomTwoBg)',
  color: 'var(--custom-background-color-two-front, $supportiveCustomTwoFg)',
};

const customThreeDefaultColors = {
  backgroundColor:
    'var(--custom-background-color-three ,$colors$teaserFooterCustomThreeBg)',
  color: 'var(--custom-background-color-three-front, $supportiveCustomThreeFg)',
};

const blackDefaultColors = {
  backgroundColor: '$teaserFooterBlackBg',
  color: '$supportiveBlackFg',
};

const highlightDefaultColors = {
  backgroundColor: '$supportiveHighlightBg',
  color: '$supportiveHighlightFg',
};

const footerPadding = {
  '--_b-footer-padding-top': 'var(--brick-space-teaserFooterTipusTopM)',
  '--_b-footer-padding-right': 'var(--brick-space-teaserFooterTipusRightM)',
  '--_b-footer-padding-bottom': 'var(--brick-space-teaserFooterTipusBottomM)',
  '--_b-footer-padding-left': 'var(--brick-space-teaserFooterTipusLeftM)',
  '@bp532': {
    '--_b-footer-padding-top': 'var(--brick-space-teaserFooterTipusTopL)',
    '--_b-footer-padding-right': 'var(--brick-space-teaserFooterTipusRightL)',
    '--_b-footer-padding-bottom': 'var(--brick-space-teaserFooterTipusBottomL)',
    '--_b-footer-padding-left': 'var(--brick-space-teaserFooterTipusLeftL)',
  },
};

export const footerContainerStyle = css({
  '--_b-footer-padding-top': 0,
  '--_b-footer-padding-right': 0,
  '--_b-footer-padding-bottom': 0,
  '--_b-footer-padding-left': 0,

  paddingTop: 'var(--_b-footer-padding-top)',
  paddingRight:
    'var(--b-teaser-padding-footer, var(--_b-footer-padding-right))',
  paddingBottom:
    'var(--b-teaser-padding-footer, var(--_b-footer-padding-bottom))',
  paddingLeft: 'var(--b-teaser-padding-footer, var(--_b-footer-padding-left))',

  boxSizing: 'border-box',

  gap: '$teaserStackBM',

  '@bp532': {
    gap: '$teaserStackBL',
  },

  variants: {
    hasByline: {
      true: {},
      false: {},
    },
    skin: {
      sport: {
        backgroundColor: '$supportiveSportBg',
        color: '$supportiveSportFg',
      },
      none: {
        color: '$supportiveNoneFg',
        backgroundColor: '$teaserFooterNoneBg',
      },
      black: {},
      'custom-one': {},
      'custom-two': {},
      'custom-three': {},
      highlight: {},
    },
    theme: {
      alt: {},
      alfa: {},
      bravo: {},
      charlie: {},
      nettavisen: {},
    },
  },

  compoundVariants: [
    {
      theme: 'alfa',
      skin: 'black',
      hasByline: false,
      css: {
        ...blackDefaultColors,
      },
    },
    {
      theme: 'bravo',
      skin: 'none',
      css: {
        ...footerPadding,
      },
    },
    {
      theme: 'bravo',
      skin: 'black',
      css: {
        ...blackDefaultColors,
        ...footerPadding,
      },
    },
    {
      theme: 'charlie',
      skin: 'black',
      hasByline: false,
      css: {
        ...blackDefaultColors,
      },
    },
    {
      theme: 'alfa',
      skin: 'highlight',
      hasByline: false,
      css: {
        ...highlightDefaultColors,
      },
    },
    {
      theme: 'charlie',
      skin: 'highlight',
      hasByline: false,
      css: {
        ...highlightDefaultColors,
      },
    },
    {
      theme: 'alfa',
      skin: 'custom-one',
      hasByline: false,
      css: {
        ...customOneDefaultColors,
      },
    },
    {
      theme: 'bravo',
      skin: 'custom-one',
      css: {
        ...customOneDefaultColors,
      },
    },
    {
      theme: 'charlie',
      skin: 'custom-one',
      hasByline: false,
      css: {
        ...customOneDefaultColors,
      },
    },
    {
      theme: 'nettavisen',
      skin: 'custom-one',
      css: {
        backgroundColor: '$teaserFooterCustomOneBg',
        color: '$supportiveCustomTwoFg',
        ...footerPadding,
      },
    },
    {
      theme: 'alfa',
      skin: 'custom-two',
      hasByline: false,
      css: {
        ...customTwoDefaultColors,
      },
    },
    {
      theme: 'bravo',
      skin: 'custom-two',
      css: {
        ...customTwoDefaultColors,
      },
    },
    {
      theme: 'charlie',
      skin: 'custom-two',
      hasByline: false,
      css: {
        ...customTwoDefaultColors,
      },
    },
    {
      theme: 'nettavisen',
      skin: 'custom-two',
      css: {
        backgroundColor: '$teaserFooterCustomTwoBg',
        color: '$supportiveCustomTwoFg',
        ...footerPadding,
      },
    },
    {
      theme: 'alfa',
      skin: 'custom-three',
      hasByline: false,
      css: {
        ...customThreeDefaultColors,
      },
    },
    {
      theme: 'charlie',
      skin: 'custom-three',
      hasByline: false,
      css: {
        ...customThreeDefaultColors,
      },
    },
  ],
});
