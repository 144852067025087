import { Design } from '@amedia/brick-tokens';
import { brickImageSrcset } from '@amedia/brick-image/template';

import type { Byline, ImageData } from '../../types.js';
import { getImageTag } from '../../utils/getImageTag.js';
import { aoiCssVar } from '../../utils/getImageData.js';

import {
  bylineContainerStyle,
  bylineStyle,
  authorTextStyle,
  roleTextStyle,
  bylineImage,
  figureStyle,
  markerStyle,
  markerContainerStyle,
  bubbleBylineStyle,
} from './byline-style.js';

function bylineImageTemplate({
  imageData,
  title,
  hasImage,
  theme,
}: {
  imageData: ImageData;
  title: string;
  hasImage: boolean;
  theme: Design;
}) {
  if (!imageData || !imageData.imgAoi) {
    return '';
  }
  const { imgAoi } = imageData;
  const srcset = brickImageSrcset({
    srcsizes: '280',
    url: imageData.url,
  });

  const imageTag = getImageTag({
    imageData: { ...imageData, sizes: '280', srcset: srcset },
    title,
  });

  const { x_aoi, y_aoi } = aoiCssVar({
    aoiX: imgAoi.x,
    aoiY: imgAoi.y,
    aoiWidth: imgAoi.width,
    aoiHeight: imgAoi.height,
    imageWidth: imgAoi.orgWidth,
    imageHeight: imgAoi.orgHeight,
  });

  return `
    <figure style="--focus-x:${x_aoi}%; --focus-y:${y_aoi}%;" class="${figureStyle}">
      <div class="${bylineImage({ theme, image: hasImage })}">
          ${imageTag}
      </div>
    </figure>
    `;
}

function getTextMarkup({
  textType,
  text,
  hasImage,
  theme,
}: {
  textType: 'author' | 'role';
  text: string;
  hasImage: boolean;
  theme: Design;
}): string {
  let markup = '';
  if (theme === 'nettavisen' && hasImage) {
    const authorMarkerStyles =
      theme === 'nettavisen' && hasImage ? markerStyle({ textType }) : '';
    const roleMarkerStyles =
      theme === 'nettavisen' && hasImage ? markerStyle({ textType }) : '';
    switch (textType) {
      case 'author':
        markup = `<p class="${authorTextStyle({
          theme,
        })}"><span class="${authorMarkerStyles}">${text}</span></p>`;
        break;
      case 'role':
        markup = `<p class="${roleTextStyle}"><span class="${roleMarkerStyles}">${text}</span></p>`;
        break;
    }
  } else {
    switch (textType) {
      case 'author':
        markup = `<p class="${authorTextStyle({
          theme,
        })}">${text}</p>`;
        break;
      case 'role':
        markup = `<p class="${roleTextStyle({
          teaserImage: hasImage,
        })}">${text}</p>`;
        break;
    }
  }

  return markup;
}

export default function bylineTemplate({
  theme,
  byline,
  hasImage = false,
  bubble = false,
}: {
  theme: Design;
  byline: Byline;
  hasImage?: boolean;
  bubble?: boolean;
}) {
  const { authorName, authorRole, authorImage } = byline;
  if (!authorName) {
    return '';
  }

  const authorImageMarkup = authorImage
    ? `${bylineImageTemplate({
        imageData: authorImage,
        title: authorName,
        theme,
        hasImage,
      })}`
    : '';

  const authorNameMarkup = getTextMarkup({
    theme,
    hasImage,
    textType: 'author',
    text: authorName,
  });
  const authorRoleMarkup = authorRole
    ? getTextMarkup({ theme, hasImage, textType: 'role', text: authorRole })
    : '';
  const containerStyles = bylineContainerStyle({
    teaserImage: hasImage,
    bylineImage: !!authorImageMarkup,
    theme,
  });
  const markerContainerStyles =
    theme === 'nettavisen' && hasImage ? markerContainerStyle : '';
  const bylineStyles = bubble
    ? bubbleBylineStyle
    : bylineStyle({
        theme,
        teaserImage: hasImage,
        bylineImage: !!authorImageMarkup,
      });

  const bylineMarkup = `
      <div itemprop="author" class="brick-teaser-byline ${containerStyles}">
      ${authorImageMarkup}
        <div class="${bylineStyles} ${markerContainerStyles}">
          ${authorNameMarkup}
          ${authorRoleMarkup}
        </div>
      </div>
    `;
  return bylineMarkup;
}
