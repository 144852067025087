import { Title } from '../../types';

import getTemplateStructure from './templates/structure';
import { titleContainerStyle } from './title-style.js';

export default function titleTemplate(data: Title, useAutolayout: boolean) {
  const {
    imageData,
    version,
    theme,
    teaserType,
    marker,
    breakingNews,
    rating,
  } = data;
  const isBreaking = breakingNews?.breakingNews;
  const imageClass = imageData ? 'has-image' : '';
  const themeStructure = getTemplateStructure(data, useAutolayout);
  return `
    <div class="title_container ${imageClass} ${titleContainerStyle({
      version,
      image: !!imageData,
      teaserType,
      theme,
      marker,
      isBreaking,
      rating,
    })}">
    ${themeStructure}
    </div>
`;
}
