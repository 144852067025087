import { css } from '../../styles';

export const overlayBylineContainerStyle = css({
  '--_b-overlay-byline-padding': 'var(--brick-space-teaserBoxInsetM)',
  gridArea: 'image',
  alignSelf: 'start',
  zIndex: '2',
  padding:
    'var(--b-teaser-padding-overlay-byline, var(--_b-overlay-byline-padding))',
  '@bp532': {
    '--_b-overlay-byline-padding': 'var(--brick-space-teaserBoxInsetL)',
  },
});

export const bylineContainerStyle = css({
  '--_b-teaser-size-avatar': 'var(--brick-sizes-avatarM)',
  '--_b-byline-top-inset': 0,
  '--_b-byline-right-inset': 0,
  '--_b-byline-bottom-inset': 0,
  '--_b-byline-left-inset': 0,

  paddingTop: 'var(--b-teaser-padding-byline-top, var(--_b-byline-top-inset))',
  paddingRight:
    'var(--b-teaser-padding-byline-right, var(--_b-byline-right-inset))',
  paddingBottom:
    'var(--b-teaser-padding-byline-bottom, var(--_b-byline-bottom-inset))',
  paddingLeft:
    'var(--b-teaser-padding-byline-left, var(--_b-byline-left-inset))',

  boxSizing: 'border-box',
  gridTemplateRows: 'minmax(min-content, 1fr)',
  borderRadius: '$pill',

  variants: {
    teaserImage: {
      true: {},
      false: {},
    },
    bylineImage: {
      true: {
        gridTemplateColumns:
          'var(--b-teaser-size-avatar, var(--_b-teaser-size-avatar)) minmax(min-content, 1fr)',
        gridTemplateAreas: '"profilepic text"',
      },
      false: {
        gridTemplateColumns: '1fr',
        gridTemplateAreas: '"text"',
      },
    },
    theme: {
      alfa: {
        display: 'grid',
        alignItems: 'center',
        gap: '$x1',
      },
      bravo: {
        display: 'inline-grid',
        gap: '$x2s',

        alignItems: 'center',
        '& .body .brick-teaser-byline': {
          display: 'none',
        },
      },
      charlie: {
        display: 'grid',
        alignItems: 'center',
        gap: '$x1',
      },
      nettavisen: {
        display: 'inline-grid',
        alignItems: 'start',
        '& .body .brick-teaser-byline': {
          display: 'none',
        },
      },
      alt: {},
    },
  },
  compoundVariants: [
    {
      teaserImage: true,
      theme: 'bravo',
      css: {
        background: 'rgba(250,250,250,0.8)',
        color: 'black',
        '@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)':
          {
            background: 'rgba(250,250,250,0.6)',
            '-webkit-backdrop-filter': 'blur(5px)',
            backdropFilter: 'blur(5px)',
          },
      },
    },
    {
      teaserImage: true,
      theme: 'nettavisen',
      css: {
        gap: 0,
      },
    },
    {
      teaserImage: false,
      theme: 'nettavisen',
      css: {
        gap: '$x1',
      },
    },
    {
      bylineImage: false,
      teaserImage: true,
      theme: 'bravo',
      css: {
        '--_b-byline-top-inset': 'var(--brick-space-x2s)',
        '--_b-byline-right-inset': 'var(--brick-space-x2s)',
        '--_b-byline-bottom-inset': 'var(--brick-space-x2s)',
        '--_b-byline-left-inset': 'var(--brick-space-x2s)',
      },
    },
    {
      bylineImage: true,
      theme: 'bravo',
      css: {
        '--_b-byline-top-inset': 0,
        '--_b-byline-right-inset': 'var(--brick-space-x2s)',
        '--_b-byline-bottom-inset': 0,
        '--_b-byline-left-inset': 0,
        height: 'var(--b-teaser-size-avatar, var(--_b-teaser-size-avatar))',
      },
    },
  ],
});

const textOverflowStyles = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
};

export const bylineStyle = css({
  $$left: '0cqi',
  $$right: '0cqi',
  $$padding: '0cqi',
  $$gaps: '0cqi',
  gridArea: 'text',
  display: 'flex',
  flexDirection: 'column',

  overflow: 'hidden',

  variants: {
    theme: {
      alfa: {},
      bravo: {
        justifyContent: 'center',
      },
      charlie: {},
      nettavisen: {},
      alt: {},
    },
    bylineImage: {
      true: {
        $$left: 'var(--b-teaser-size-avatar, var(--_b-teaser-size-avatar))',
        $$gaps: '$space$x2',
      },
      false: {},
    },
    teaserImage: {
      true: {
        maxWidth: '220px',
        '@supports (container: inline-size)': {
          maxWidth: 'calc(100cqi - $$left - $$padding - $$right - $$gaps)',
        },
      },
      false: {
        container: 'byline-content / inline-size',
      },
    },
  },
  compoundVariants: [
    {
      teaserImage: false,
      theme: 'nettavisen',
      css: {
        '& p': {
          ...textOverflowStyles,
          maxWidth: '220px',
          '@supports (container: inline-size)': {
            maxWidth: '100cqi',
          },
        },
      },
    },
    {
      teaserImage: false,
      theme: 'bravo',
      css: {
        '& p': {
          ...textOverflowStyles,
          maxWidth: '220px',
          '@supports (container: inline-size)': {
            maxWidth: '100cqi',
          },
        },
      },
    },
    {
      teaserImage: true,
      theme: 'bravo',
      css: {
        $$padding: 'calc($space$teaserBoxInsetM * 2 + $space$x2 * 2)',
        '& p': {
          ...textOverflowStyles,
        },
      },
    },
  ],
});

export const authorTextStyle = css({
  fontstyle: 'avatarMetaL',
  margin: 0,

  variants: {
    theme: {
      alfa: {},
      bravo: {},
      charlie: {},
      nettavisen: {
        lineHeight: '1.35',
      },
      alt: {},
    },
  },
});

export const roleTextStyle = css({
  fontstyle: 'baseMetaL',
  margin: 0,
});

export const bylineImage = css({
  gridArea: 'profilepic',
  borderRadius: '$avatar',
  backgroundColor: 'transparent',
  height: 'var(--b-teaser-size-avatar, var(--_b-teaser-size-avatar))',
  width: 'var(--b-teaser-size-avatar, var(--_b-teaser-size-avatar))',
  position: 'relative',
  overflow: 'hidden',
  minHeight: 'var(--focus-min-height)',
  maxHeight: 'var(--focus-max-height)',

  '& img': {
    objectFit: 'cover',
    objectPosition: 'left var(--focus-x) top var(--focus-y)',
    height: '100%',
    aspectRatio: 1,
  },

  variants: {
    theme: { alfa: {}, bravo: {}, charlie: {}, nettavisen: {}, alt: {} },
    image: {
      true: {},
      false: {},
    },
  },

  compoundVariants: [
    {
      theme: 'bravo',
      image: true,
      css: {
        borderRadius: '$avatar 0 0 $avatar',
      },
    },
  ],
});

export const figureStyle = css({
  //reset default user agent style sheet on figure element
  marginBlockStart: 0,
  marginBlockEnd: 0,
  marginInlineStart: 0,
  marginInlineEnd: 0,
  margin: 0,
});

export const bubbleBylineStyle = css({
  container: 'byline-content / inline-size',
  gridArea: 'text',
  display: 'flex',
  flexDirection: 'column',

  overflow: 'hidden',

  '& p': {
    ...textOverflowStyles,
    maxWidth: '220px',
    '@supports (container: inline-size)': {
      maxWidth: '100cqi',

      //remove when refactor is done and parent element size is set by a grid space
      '[data-theme="alfa"].premium &, [data-theme="charlie"].premium &': {
        maxWidth: 'calc(100cqi - $sizes$premiumIconM)',
      },
    },
  },
});

export const markerContainerStyle = css({
  overflow: 'hidden',
});

export const markerStyle = css({
  color: 'white',
  background: 'black',
  '-webkit-box-decoration-break': 'clone',
  boxDecorationBreak: 'clone',

  variants: {
    textType: {
      author: {
        lineHeight: '1.4',
        padding: '2px 6px 2px',
      },
      role: {
        lineHeight: '1.25',
        padding: '1px 6px 2px',
      },
    },
  },
});
